import React, { useState } from "react";
import RescheduleForm from "./RescheduleForm";
import Transfer from "./Transfer";
import { Button } from "react-bootstrap";
import DeleteForm from "./DeleteForm";
import NoteForm from "./NoteForm";
import Claim from "./Claim";

export default function ShiftModal(props) {
  const {
    selectedShift,
    setSelectedShift,
    mentorNameOrEmail,
    timezone,
    moveShift,
    searchForMentor,
    setMentorNameOrEmail,
    foundMentors,
    setFoundMentors,
    claimShift,
    transferFormValid,
    is_admin,
    user,
    deleteShift,
    currentScheduleDisplayed,
    updateShiftNotes,
    setNewShift,
    closeAll
  } = props;

  const [mode, setMode] = useState(null);

  const closeModal = () => {
    setFoundMentors(null);
    setMentorNameOrEmail("");
    setSelectedShift(null);
    setNewShift(null)
  };

  const updateMode = (newMode) => {
    if (shiftFeatures[newMode].enabled) {
      setMode(newMode);
    }
  };

  const shiftType = selectedShift.tags.includes("TI") ? "Tech interview" : selectedShift.tags.includes("Docks") ? "Dockside Session" : "Mentoring";
  const isTI = shiftType === "Tech interview";
  const isClaimable = selectedShift?.user_id.includes("?"); // && !selectedShift.tags.includes("TI")
  const isOwner = selectedShift?.user_id === user;
  const isAdmin = is_admin;

  const shiftFeatures = {
    delete: { enabled: isAdmin, label: "Delete" },
    note: { enabled: isOwner || isAdmin, label: "Update Note" },
    reschedule: { enabled: (isOwner && isTI) || isAdmin, label: "Reschedule" },
    transfer: { enabled: isOwner || isAdmin, label: "Transfer" },
    claim: { enabled: isClaimable, label: "Claim" },
  };
  const featureButtons = Object.keys(shiftFeatures).map((feature) => (
    <Button
      variant={`${mode === feature ? "primary" : "secondary"}`}
      onClick={() => updateMode(feature)}
      disabled={!shiftFeatures[feature].enabled}
    >
      {shiftFeatures[feature].label}
    </Button>
  ));

  return (
    <div className="mt-5 col-md-6">
      <header>
        <h1>Shift Information</h1>
        <ul>
          <li>Shift id: {selectedShift.id}</li>
          <li>Shift type: {shiftType}</li>
          {currentScheduleDisplayed !== "My Schedule" && (
            selectedShift.user_id ?
            <li>Assigned mentor: {selectedShift.title} (#{selectedShift.user_id})</li>
            :
            <li>No mentor assigned</li>
          )}
          <li>
            Shift start: {selectedShift.start.toLocaleDateString()}{" "}
            {selectedShift.start.toLocaleTimeString()}
          </li>
          <li>
            Shift end: {selectedShift.end.toLocaleDateString()}{" "}
            {selectedShift.end.toLocaleTimeString()}
          </li>
          <li>Note: {selectedShift.note}</li>
        </ul>
        <div style={{ display: "flex", gap: "0.5em", marginBottom: "1em" }}>
          {featureButtons}
          <Button variant="primary" onClick={closeAll}>
            Close
          </Button>
        </div>
      </header>
      <main>
        {mode === "claim" && (
          <Claim
            setShowClaimModal={setSelectedShift}
            claimShift={claimShift}
            selectedShift={selectedShift}
          />
        )}
        {mode === "delete" && (
          <DeleteForm selectedShift={selectedShift} deleteShift={deleteShift} />
        )}
        {mode === "note" && (
          <NoteForm selectedShift={selectedShift} updateShiftNotes={updateShiftNotes} />
        )}
        {mode === "reschedule" && (
          <RescheduleForm
            setShowTransferModal={setSelectedShift}
            selectedShift={selectedShift}
            mentorNameOrEmail={mentorNameOrEmail}
            timezone={timezone}
            moveShift={moveShift}
          />
        )}
        {mode === "transfer" && (
          <Transfer
            setShowTransferModal={setSelectedShift}
            search={searchForMentor}
            selectedShift={selectedShift}
            mentorNameOrEmail={mentorNameOrEmail}
            setMentorNameOrEmail={setMentorNameOrEmail}
            foundMentors={foundMentors}
            setFoundMentors={setFoundMentors}
            claimShift={claimShift}
            isValid={transferFormValid}
          />
        )}
      </main>
    </div>
  );
}
