import React from 'react';
import Alert from 'react-bootstrap/Alert';
import PropTypes from 'prop-types';

const toastCopy = {
  danger: {
    header: "Oh snap! Something's not right!",
    body: 'Please try again. If the issue persists, please reach out to your shift coordinator. ',
  },
  success: {
    header: 'Success!',
    body: 'Your action has been successfuly completed! ',
  },
};
function Toast({ show, setShow, variant }) {
  return (
    <Alert fade="false" show={show} variant={variant} onClose={() => setShow(false)} dismissible style={{ opacity: 1 }}>
      <Alert.Heading>{toastCopy[variant] && toastCopy[variant].header}</Alert.Heading>
      <p>{toastCopy[variant] && toastCopy[variant].body}</p>
    </Alert>
  );
}

export default Toast;

Toast.propTypes = {
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};
