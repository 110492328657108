import { createClient } from "@libsql/client";
import { parsedMentorSeed } from "./mentorSeedData";

const client = createClient({
  url: "libsql://bolsun-temp-francisbourgouin.turso.io",
  authToken:
    "eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJhIjoicnciLCJpYXQiOjE3MTI2NzQ1NzgsImlkIjoiYzYyMTg0OTUtMjIyMS00NDIzLWIwMmEtYzFlYWU2YjI5M2ZjIn0.hMTIBcdGb9ptpV_ECQdbrUeCaXA1_S4d4NiZN2sJvURGMMoZc6U_j24F0WLuaevV14vGGNlbzcSLQqg8M74ZAQ",
});

const schema = `
  DROP TABLE IF EXISTS conference_entries;
  DROP TABLE IF EXISTS calendar_configs;
  DROP TABLE IF EXISTS excluded_programs;
  DROP TABLE IF EXISTS mentors;

  CREATE TABLE conference_entries (
    id INTEGER PRIMARY KEY,
    name TEXT NOT NULL,
    type TEXT NOT NULL,
    start_datetime TIMESTAMP NOT NULL,
    end_datetime TIMESTAMP NOT NULL,
    conference_uuid TEXT NOT NULL,
    cohort_uuid TEXT NOT NULL,
    mentor_clocktower_id TEXT NOT NULL,
    google_calendar_event_id TEXT,
    gcal_event_sent BOOLEAN
  );

  CREATE TABLE calendar_configs (
    id INTEGER PRIMARY KEY,
    program_uuid TEXT NOT NULL,
    name TEXT NOT NULL,
    amount_of_hours INTEGER NOT NULL,
    hour_start INTEGER NOT NULL,
    days_to_show TEXT NOT NULL
  );

  CREATE TABLE excluded_programs (
    id INTEGER PRIMARY KEY,
    program_uuid TEXT NOT NULL,
    name TEXT NOT NULL
  );

  CREATE TABLE mentors (
    id INTEGER PRIMARY KEY,
    mentor_clocktower_id TEXT NOT NULL,
    name TEXT NOT NULL,
    email TEXT NOT NULL,
    discord_id TEXT NOT NULL,
    years_of_experience INTEGER NOT NULL,
    titles TEXT NOT NULL,
    language_skills TEXT NOT NULL,
    library_skills TEXT NOT NULL,
    other_skills TEXT NOT NULL
  );

  CREATE UNIQUE INDEX idx_conference_entries ON conference_entries (conference_uuid, cohort_uuid);

  INSERT INTO
    calendar_configs
      (name, program_uuid, amount_of_hours, hour_start, days_to_show)
  VALUES
    ('Cyber Security Flex', '1d5ed0ee-c29f-4c02-9ab7-f76114e7190f', 8, 20, '[1,2,3,4,5]'),
    ('Cyber Security Immersive', '8203cf1a-5852-4801-a011-191b9b2e5167', 8, 15, '[1,2,3,4,5]'),
    ('Data Analytics Flex', 'b5d1fa3c-1783-4dd8-b483-527e3158ac69', 10, 16, '[1,2,3,4,5,6]'),
    ('Data Analytics Immersive', 'ce702c64-ee4e-4a1c-bafc-0f4b30d62c0b', 8, 15, '[1,2,3,4,5,6]'),
    ('Data Science Flex 2.0', '0d733d6b-f3bf-4fa6-bc3a-fa24a8a250aa', 10, 20, '[1,2,3,4,5]'),
    ('Data Science Immersive 5.0', '04f35f24-cd46-46a1-881c-eee01b1a9b49', 10, 15, '[1,2,3,4,5,6]'),
    ('Intro to Data', '010555e0-6bfb-4165-b34a-6b911e9d09dc', 10, 16, '[1,2,3,4,5,6]'),
    ('Web Flex 2.0', '471ac8e4-1df2-47f3-ac3e-ef89a334d48d', 12, 16, '[1,2,3,4,5,6]'),
    ('Web Immersive 2.0', '08c0765f-d36f-4d08-8a3d-7b49fe6115d3', 10, 15, '[1,2,3,4,5]'),
    ('Web P/T Frontend', '179872c8-e0a5-480e-b2b8-8b71cbcb236e', 10, 15, '[1,2,3,4,5,6]');

  INSERT INTO
    excluded_programs
      (program_uuid, name)
  VALUES
    ('73b79d97-4010-453a-9481-b4493b44f479', 'Career Compass'),
    ('179872c8-e0a5-480e-b2b8-8b71cbcb236e', 'Web PT Frontend'),
    ('010555e0-6bfb-4165-b34a-6b911e9d09dc', 'Intro to Data');
  `;

export const resetDb = async () => {
  const result = await client.executeMultiple(schema);
  return result;
};

export const seedDb = async () => {
  const seeds = [parsedMentorSeed];

  const result = await client.batch(seeds[0], "write");

  return result;
};

export const fetchInstructors = async () => {
  const url = "/api/contractors/lecturers";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
  };

  const res = await fetch(url, { headers });
  const data = await res.json();

  const parsedData = data.map((inst) => ({
    mentor_clocktower_id: String(inst.id),
    name: inst.fullname,
    firstName: inst.firstname,
    lastName: inst.lastname,
    email: inst.email,
  }));

  return parsedData;
};

export const fetchBookedConferences = async () => {
  // console.log("Called booked conferences")
  // const bookedConferences = await client.execute(
  //   "SELECT * FROM conference_entries"
  // );

  const url = "/api/v1/scheduling/conference-entries";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
  };

  // const res = await fetch(url, { headers });
  const res = await fetch(url);
  const { status, data } = await res.json();

  return data;
};

export const fetchExcludedPrograms = async () => {
  // const excludedPrograms = await client.execute(
  //   "SELECT * FROM excluded_programs"
  // );

  // return excludedPrograms.rows.map((row) => row.program_uuid);
  const url = "/api/v1/scheduling/excluded-calendar-programs";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
  };

  // const res = await fetch(url, { headers });
  const res = await fetch(url);
  const { status, data } = await res.json();

  return data.map((row) => row.program_uuid);
};
export const fetchCalendarConfigs = async () => {
  // const programCalendarConfigs = await client.execute(
  //   `SELECT
  //   program_uuid,
  //   name,
  //   amount_of_hours AS "amountOfHours",
  //   hour_start AS "hourStart",
  //   days_to_show AS "daysToShow"
  //   FROM calendar_configs`
  // );

  // return programCalendarConfigs.rows;

  const url = "/api/v1/scheduling/program-calendar-configs";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
  };

  // const res = await fetch(url, { headers });
  const res = await fetch(url);

  const { status, data } = await res.json();
  console.log(data, status);
  return data;
};

export const updateConferenceEntry = async (entryInfo) => {
  const {
    name,
    type,
    conference_uuid,
    cohort_uuid,
    mentor_clocktower_id,
    google_calendar_event_id,
    gcal_event_sent,
    start_datetime,
    end_datetime,
  } = entryInfo;

  // const queryStr = `
  // INSERT OR REPLACE INTO
  //   conference_entries (name, type, conference_uuid, cohort_uuid, mentor_clocktower_id, google_calendar_event_id, gcal_event_sent, start_datetime, end_datetime)
  // VALUES
  //   (?, ?, ?, ?, ?, ?, ?, ?, ?)
  // RETURNING
  // *
  // `;

  // const queryParams = [
  //   name,
  //   type,
  //   conference_uuid,
  //   cohort_uuid,
  //   mentor_clocktower_id,
  //   google_calendar_event_id,
  //   gcal_event_sent,
  //   start_datetime,
  //   end_datetime,
  // ];

  // const conferenceResult = await client.execute({
  //   sql: queryStr,
  //   args: queryParams,
  // });

  // return conferenceResult.rows[0];

  const url = "/api/v1/scheduling/conference-entries";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
    "Content-Type": "application/json",
  };
  const parsedData = JSON.stringify({
    name,
    type,
    conference_uuid,
    cohort_uuid,
    mentor_clocktower_id,
    google_calendar_event_id,
    gcal_event_sent,
    start_datetime,
    end_datetime,
  });
  const method = "POST";
  const res = await fetch(url, { method, headers, body: parsedData });

  const { status, data } = await res.json();
  return data;
};

export const deleteConferenceEntry = async (conference) => {
  const { conference_uuid, cohort_uuid } = conference;

  // const queryStr = `
  // DELETE FROM
  //   conference_entries
  // WHERE
  //   conference_uuid = ?
  // AND
  //   cohort_uuid = ?
  // RETURNING
  // *
  // `;

  // const queryParams = [conference_uuid, cohort_uuid];

  // const conferenceResult = await client.execute({
  //   sql: queryStr,
  //   args: queryParams,
  // });

  // return conferenceResult.rows[0];

  const url = "/api/v1/scheduling/conference-entries";
  const headers = {
    Authorization: "Token token=ljkhadfjhegrhjfvkj2833asjkjkd",
    "Content-Type": "application/json",
  };
  const parsedData = JSON.stringify({
    conference_uuid,
    cohort_uuid,
  });
  const method = "DELETE";
  const res = await fetch(url, { method, headers, body: parsedData });

  const { status, data } = await res.json();
  return data;
};

const runScripts = async () => {
  try {
    await resetDb();
    console.log("DB: OK");
  } catch (e) {
    console.log("DB: OH NO");
    console.log(e);
  }

  try {
    await seedDb();
    console.log("SEED: OK");
  } catch (e) {
    console.log("SEED: OH NO");
    console.log(e);
  }
};

// runScripts();
