import { getDayOfYear } from "date-fns";
import { mentorShifts } from "../data/mentorData";

const mergeShifts = (shifts) => {
  const mergedShifts = []
  console.log(shifts.length)

  const shiftsByUserId = shifts.reduce((acc, shift) => {
    !acc[shift.user_id] && (acc[shift.user_id] = []);
    acc[shift.user_id].push(shift);

    return acc;
  }, {});
  
  Object.keys(shiftsByUserId).map(userId => {
    const sortedShifts = shiftsByUserId[userId].sort(
      (a, b) => Number(a.shift_hour) - Number(b.shift_hour)
    );

    const shiftsByDay = sortedShifts.reduce((acc, shift) => {
      const dayOfYear = getDayOfYear(shift.start);
      !acc[dayOfYear] && (acc[dayOfYear] = []);
      acc[dayOfYear].push(shift);
  
      return acc;
    }, {});

    const generatedShifts = Object.keys(shiftsByDay).map((day) => {
      const shiftsForDay = shiftsByDay[day];
      const originalShiftIds = shiftsForDay.map((shift) => shift.id);
      const output = {
        ...shiftsForDay[0],
        id: "gen-" + shiftsForDay[0].id,
        end: shiftsForDay[shiftsForDay.length - 1].end,
        originalShiftIds,
      };
      return output;
    });

    mergedShifts.push(...generatedShifts)
  })




  return mergedShifts
};
const t0 = performance.now();
const result = mergeShifts(mentorShifts);
const t1 = performance.now();
// console.log(result)
console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);