import React from 'react';
import { screen, render, waitFor } from '@testing-library/react';
import TimezoneToggle from '../TimezoneToggle';

describe('Timezone toggle', () => {
  it('TimezoneToggle displays current timezone to user', async () => {
    render(
      <TimezoneToggle timezone="EST" setTimezone={() => {}} />,
    );

    const dropdownButton = await waitFor(() => screen.getByText('EST'));
    expect(dropdownButton).toBeTruthy();
  });
  // it('TimezoneToggle displays list of options', async () => {
  //   render(
  //     <TimezoneToggle timezone="EST" setTimezone={() => {}} />,
  //   );

  //     const dropdownButton = await waitFor(() => screen.getByText('EST'));
  //     expect(dropdownButton).toBeTruthy()

  //     console.log(screen.debug())

  //     // validTimezones.forEach(async (timezone) => {
  //     //     const text = await screen.getByText(timezone.code)
  //     //     expect(text).toBeTruthy()
  //     //   })

  // })
});
