const validPrograms = [{
  name: 'Web Schedule',
  tag: 'Web',
},
{
  name: 'Data Schedule',
  tag: 'Data',
},
{
  name: 'Cyber Schedule',
  tag: 'Cyber',
},
];

export default validPrograms;
