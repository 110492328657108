import React from "react";
import { useEffect, useMemo, useState } from "react";

export default function LectureModal(props) {
  const {
    conference,
    instructors: baseInstructors,
    selectedInstructorId,
    closeModal,
    bookConference,
    deleteConference,
    deleteConfAndInvite,
    sendConferenceInvite,
    isAdmin,
    isProcessing,
  } = props;

  const instructors = [...baseInstructors].map((inst) => {
    let status = "free";
    if (conference.bookedInstructors.day.includes(inst.mentor_clocktower_id)) {
      status = "booked";
    }
    if (conference.bookedInstructors.hour.includes(inst.mentor_clocktower_id)) {
      status = "dblbooked";
    }
    return { ...inst, status };
  });

  const fetchInstructor = (instructorId) =>
    instructors.find((inst) => inst.mentor_clocktower_id === instructorId) || null;

  const [filter, setFilter] = useState("");
  const [currentInstructor, setCurrentInstructor] = useState(
    fetchInstructor(selectedInstructorId)
  );
  const hasGcalInvite = !!conference.google_calendar_event_id;
  const isSameInstructor =
    selectedInstructorId === currentInstructor?.mentor_clocktower_id;

  const selectInstructor = (instructorId) =>
    setCurrentInstructor(fetchInstructor(instructorId));

  const filteredInstructors =
    filter.length > 1
      ? instructors.filter((inst) =>
          inst.name.toLowerCase().includes(filter.toLowerCase())
        )
      : instructors;

  const parsedInstructorsButtons = filteredInstructors.slice(0, 30).map((instructor) => (
    <button
      readOnly
      key={instructor.mentor_clocktower_id}
      className={instructor.status}
      onClick={() => selectInstructor(instructor.mentor_clocktower_id)}
    >
      {instructor.name}
    </button>
  ));

  const convertToDateTimeLocalString = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    const escClose = (event) => event.key === "Escape" && closeModal();

    document.addEventListener("keydown", escClose);

    return () => document.removeEventListener("keydown", escClose);
  });

  const handleCloseClick = (event) => {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  };

  return (
    <div className="bosun_modal_bg" onClick={handleCloseClick}>
      <div className="bosun_modal">
        <h1 className="conference">
          <span>{conference.name}</span>
          {(conference.week_label || conference.module_label) &&
          <span>
            {conference.cohort.name} - (
            {(conference.module_label || conference.week_label).toUpperCase()})
          </span>
          }
        </h1>
        <h2 className="cohort">
          <span>Current instructor: {currentInstructor?.name}</span>
        </h2>
        {isAdmin && (
          <>
            <h2>Book another instructor</h2>

            <div className="selectList">
              <input
                type="text"
                placeholder="Filter names..."
                value={filter}
                autoFocus
                onChange={(event) => setFilter(event.target.value)}
              />
              <div className="buttonList">{parsedInstructorsButtons}</div>
            </div>
          </>
        )}

        <div style={{ marginTop: "2em" }} className="timeChange">
          <h2>Current Lecture hour</h2>
          <div>
            <label htmlFor="">Start:</label>
            <input
              type="datetime-local"
              readOnly
              value={convertToDateTimeLocalString(conference.start_datetime)}
            />
          </div>
          <div style={{ marginTop: "1em" }}>
            <label htmlFor="">End:</label>
            <input
              type="datetime-local"
              readOnly
              value={convertToDateTimeLocalString(conference.end_datetime)}
            />
          </div>
        </div>

        <div style={{ marginTop: "2em", display: "flex", gap: "1em" }}>
          {hasGcalInvite && isAdmin && (
            <>
              <button
                disabled={isProcessing}
                onClick={() => deleteConfAndInvite(conference)}
              >
                Remove Instructor & Invite
              </button>
            </>
          )}
          {!hasGcalInvite && currentInstructor && selectedInstructorId && isAdmin && (
            <>
              <button onClick={() => deleteConference(conference)}>
                Remove Instructor
              </button>
            </>
          )}
          {!hasGcalInvite && currentInstructor && !selectedInstructorId && isAdmin && (
            <>
              <button onClick={() => setCurrentInstructor(null)}>
                Remove Instructor
              </button>
            </>
          )}
          {hasGcalInvite && !isSameInstructor && isAdmin && (
            <>
              <button
                disabled={isProcessing}
                onClick={() =>
                  sendConferenceInvite({
                    ...conference,
                    mentor_clocktower_id: currentInstructor.mentor_clocktower_id,
                  })
                }
              >
                Update Instructor & Invite
              </button>
              <button onClick={() => bookConference(conference)}>
                Update Instructor
              </button>
            </>
          )}

          {!hasGcalInvite && currentInstructor && isAdmin && (
            <>
              <button
                disabled={isProcessing}
                onClick={() =>
                  sendConferenceInvite({
                    ...conference,
                    mentor_clocktower_id: currentInstructor.mentor_clocktower_id,
                  })
                }
              >
                Save & Send Calendar Invite
              </button>
              <button onClick={() => bookConference(conference, currentInstructor)}>
                Save Changes
              </button>
            </>
          )}
          <button onClick={closeModal} style={{ marginLeft: "auto" }}>
            Close Modal
          </button>
        </div>
      </div>
    </div>
  );
}
