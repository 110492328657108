import React from "react";
import {
  startOfWeek,
  startOfMonth,
  addDays,
  addWeeks,
  addMonths,
  isToday,
} from "date-fns";
import { useState } from "react";

const configureGridCells = (
  viewMode, //: "WEEK" | "MONTH",
  daysToShow, //: number[],
  amountOfHours //: number
) => {
  if (viewMode === "WEEK") {
    document.documentElement.style.setProperty(
      "--lsc-calendar-rows",
      `${amountOfHours * 2}`
    );
    document.documentElement.style.setProperty(
      "--lsc-calendar-columns",
      `${daysToShow.length}`
    );
  }
  if (viewMode === "MONTH") {
    document.documentElement.style.setProperty("--lsc-calendar-rows", `${5}`);
    document.documentElement.style.setProperty(
      "--lsc-calendar-columns",
      `${7}`
    );
  }
};

export default function useCalendar(params) {
  const { date, hourStart, amountOfHours, daysToShow } = params;

  // const initialViewMode = "WEEK";
  const initialViewMode = "MONTH";
  const [viewMode, setViewMode] = useState(initialViewMode);

  let firstDay;
  if (viewMode === "WEEK") {
    firstDay = startOfWeek(date ? new Date(date) : new Date());
  }
  if (viewMode === "MONTH") {
    firstDay = startOfMonth(date ? new Date(date) : new Date());
  }

  const parsedHourStart = hourStart - new Date().getTimezoneOffset() / 60;

  const [currentDay, setCurrentDay] = useState(firstDay);

  configureGridCells(viewMode, daysToShow, amountOfHours);

  const nextWeek = () => setCurrentDay(addWeeks(currentDay, 1));
  const previousWeek = () => setCurrentDay(addWeeks(currentDay, -1));
  const currentWeek = () => setCurrentDay(startOfWeek(new Date()));
  const customWeek = (event) =>
    setCurrentDay(startOfWeek(new Date(event.target.value)));

  const nextMonth = () => setCurrentDay(addMonths(currentDay, 1));
  const previousMonth = () => setCurrentDay(addMonths(currentDay, -1));
  const currentMonth = () => setCurrentDay(startOfMonth(new Date()));
  const customMonth = (event) =>
    setCurrentDay(startOfMonth(new Date(event.target.value)));

  const days = [
    {
      dayOfWeek: 0,
      name: "Sunday",
      date: addDays(currentDay, 0),
      isToday: isToday(addDays(currentDay, 0)),
      isSkipped: !daysToShow.includes(0),
    },
    {
      dayOfWeek: 1,
      name: "Monday",
      date: addDays(currentDay, 1),
      isToday: isToday(addDays(currentDay, 1)),
      isSkipped: !daysToShow.includes(1),
    },
    {
      dayOfWeek: 2,
      name: "Tuesday",
      date: addDays(currentDay, 2),
      isToday: isToday(addDays(currentDay, 2)),
      isSkipped: !daysToShow.includes(2),
    },
    {
      dayOfWeek: 3,
      name: "Wednesday",
      date: addDays(currentDay, 3),
      isToday: isToday(addDays(currentDay, 3)),
      isSkipped: !daysToShow.includes(3),
    },
    {
      dayOfWeek: 4,
      name: "Thursday",
      date: addDays(currentDay, 4),
      isToday: isToday(addDays(currentDay, 4)),
      isSkipped: !daysToShow.includes(4),
    },
    {
      dayOfWeek: 5,
      name: "Friday",
      date: addDays(currentDay, 5),
      isToday: isToday(addDays(currentDay, 5)),
      isSkipped: !daysToShow.includes(5),
    },
    {
      dayOfWeek: 6,
      name: "Saturday",
      date: addDays(currentDay, 6),
      isToday: isToday(addDays(currentDay, 6)),
      isSkipped: !daysToShow.includes(6),
    },
  ];

  const weekHours = [...Array(amountOfHours)].map(
    (_, index) => parsedHourStart + index + ":00"
  );

  const weekCells = [...Array(amountOfHours * daysToShow.length * 2)].map(
    (_, index) =>
      Math.floor(index / (daysToShow.length * 2)) % 2 === 0 ? "even" : "odd"
  );
  const monthCells = [...Array(5 * 7)].map((_, index) =>
    Math.floor(index) % 2 === 0 ? "even" : "odd"
  );

  const toggleViewMode = () => {
    const updatedViewMode = viewMode === "WEEK" ? "MONTH" : "WEEK";

    setViewMode(updatedViewMode);
    let firstDay;
    if (updatedViewMode === "MONTH") {
      firstDay = startOfMonth(currentDay ? new Date(date) : new Date());
      setCurrentDay(firstDay);
    }
    if (updatedViewMode === "WEEK") {
      firstDay = startOfWeek(currentDay ? new Date(date) : new Date());
      setCurrentDay(firstDay);
    }
  };

  return {
    viewMode,
    currentDay,
    toggleViewMode,
    month: {
      days: days,
      cells: monthCells,
      firstDay: currentDay,
      goNext: nextMonth,
      goBack: previousMonth,
      goCurrent: currentMonth,
      goCustom: customMonth,
    },
    week: {
      cellOffset: parsedHourStart,
      hours: weekHours,
      days: days,
      cells: weekCells,
      goNext: nextWeek,
      goBack: previousWeek,
      goCurrent: currentWeek,
      goCustom: customWeek,
    },
  };
}
