export const buildCreateOrUpdateEventPayload = (
  confInvite,
  cohort,
  instructor,
  currentProgram
) => {
  const url = confInvite.google_calendar_event_id ? `/api/v1/gcal/update-event/${confInvite.google_calendar_event_id}` : "/api/v1/gcal/create-event";
  console.log(confInvite, url)
  const method = "POST";
  const headers = { "Content-Type": "application/json" };

  const apiKey =
    "e35eb7e62ac44ed58f9b1efe8da27d1d5435331c2308408a91b25f14c4fd4bee9b42161fbf82";
  const activityUrl = `https://web.compass.lighthouselabs.ca/p/${currentProgram.id}/${confInvite.conference_uuid}`;
  const summary = `${instructor.name}: ${confInvite.name} - ${cohort.name}`;
  const description = `${confInvite.name} \n${cohort.name} \n${instructor.name} \n${confInvite.start_datetime} \n${activityUrl}`;
  const attendees = [{ email: instructor.email }];
  const body = {
    apiKey,
    summary,
    location: activityUrl,
    description,
    start: {
      dateTime: confInvite.start_datetime,
      timeZone: "America/Vancouver",
    },
    end: {
      dateTime: confInvite.end_datetime,
      timeZone: "America/Vancouver",
    },
    attendees,
  };
  const stringBody = JSON.stringify(body);

  return { url, payload: { headers, method, body: stringBody } };
};

export const buildDeleteEventPayload = (confInvite) => {
  const url = `/api/v1/gcal/delete-event/${confInvite.google_calendar_event_id}`;
  const method = "POST";
  const headers = { "Content-Type": "application/json" };

  const apiKey =
    "e35eb7e62ac44ed58f9b1efe8da27d1d5435331c2308408a91b25f14c4fd4bee9b42161fbf82";

  const body = { apiKey };
  const sringBody = JSON.stringify(body);

  return { url, payload: { headers, method, body: sringBody } };
};


