import React from "react";
// type MonthViewProps = {
//   events: Object[];
//   hours: string[];
//   currentDay: Date;
//   days: {
//     dayOfWeek: number;
//     name: string;
//     date: Date;
//     isToday: boolean;
//     isSkipped: boolean;
//   }[];
//   cells: ("even" | "odd")[];
//   goNext: () => void;
//   goBack: () => void;
//   goCurrent: () => void;
//   goCustom: () => void;
// };

import { addDays, getDaysInMonth, isSameDay } from "date-fns";
import { formatDateForInput } from "../../helpers/dateHelpers";
import Event from "./Event";

export default function MonthView(props) {
  const {
    currentDay,
    cells,
    days,
    hours,
    firstDay,
    goBack,
    goCurrent,
    goNext,
    goCustom,
    events,
    toggleViewMode,
  } = props;

  const monthDays = [];
  for (let i = 0; i < firstDay.getDay(); i++) {
    monthDays.push("");
  }
  for (let i = 0; i < getDaysInMonth(firstDay); i++) {
    const newDay = addDays(firstDay, i);
    monthDays.push(newDay);
  }

  const parsedCells = cells.map((cellClass, index) => (
    <div
      key={index}
      className={`${cellClass} ${monthDays[index] ? "inmonth" : "outmonth"}`}
    >
      <span style={{ fontSize: "1rem" }}>
        {monthDays[index] && monthDays[index].getDate()}
      </span>
      <span style={{ fontSize: "0.6rem" }}>
        {events
          .filter((event) => isSameDay(new Date(event.start), monthDays[index]))
          .map((event) => (
            <Event {...event} />
          ))}
      </span>
    </div>
  ));

  const parsedDays = days.map((day) => (
    <p key={day.dayOfWeek}>
      <span>{day.name}</span>
    </p>
  ));
  return (
    <>
      <nav>
        <input
          onChange={goCustom}
          type="date"
          value={formatDateForInput(currentDay)}
        />
        <button onClick={goBack}>Previous Month</button>
        <button onClick={goCurrent}>Current Month</button>
        <button onClick={goNext}>Next Month</button>
        <button onClick={toggleViewMode}>Week View</button>
      </nav>
      <main className="Month">
        <div className="days">{parsedDays}</div>
        <div className="cells">{parsedCells}</div>
      </main>
    </>
  );
}
