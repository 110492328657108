import React from "react";
// type WeekViewProps = {
//   hours: string[];
//   currentDay: Date;
//   events: Object[];
//   days: {
//     dayOfWeek: number;
//     name: string;
//     date: Date;
//     isToday: boolean;
//     isSkipped: boolean;
//   }[];
//   cells: ("even" | "odd")[];
//   goNext: () => void;
//   goBack: () => void;
//   goCurrent: () => void;
//   goCustom: () => void;
//   toggleViewMode: () => void;
// };

import { formatDateForInput } from "../../helpers/dateHelpers";
import Event from "./Event";

export default function WeekView(props) {
  const {
    currentDay,
    cells,
    days,
    hours,
    goBack,
    goCurrent,
    goNext,
    goCustom,
    events,
    toggleViewMode,
    cellOffset
  } = props;

  const parsedCells = cells.map((cellClass, index) => (
    <div key={index} className={cellClass}></div>
  ));

  const parsedHours = hours.map((hour) => <div key={hour}>{hour}</div>);

  const parsedDays = days.map(
    (day) =>
      !day.isSkipped && (
        <p key={day.dayOfWeek} className={day.isToday ? "today" : ""}>
          <span>{day.name}</span>
          <span>{day.date.toLocaleDateString()}</span>
        </p>
      )
  );

  const parsedEvents = events.map((event) => <Event {...event} cellOffset={cellOffset} type="WEEK"/>);

  return (
    <>
      <nav>
        <input onChange={goCustom} type="date" value={formatDateForInput(currentDay)} />
        <button onClick={goBack}>Previous Week</button>
        <button onClick={goCurrent}>Current week</button>
        <button onClick={goNext}>Next week</button>
        <button onClick={toggleViewMode}>Month View</button>
      </nav>
      <main>
        <div className="days">
          <p>&nbsp;</p>
          {parsedDays}
        </div>
        <div className="hours">{parsedHours}</div>
        <div className="cells">{parsedCells}</div>
        <div className="events">{parsedEvents}</div>
      </main>
    </>
  );
}
