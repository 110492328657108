export const mentorSeedData = {
  "Alfredo Salazar": {
      "email": "alfredosv08@gmail.com",
      "discordID": "350762091499749378",
      "yearsOfExperience": 10,
      "titles": [
          "Fullstack Developer",
          "Senior Software Developer",
          "Instructor",
          "Professor",
          "Mentor",
          "Advisory Board Member"
      ],
      "skills": {
          "languages": [
              "PHP",
              "JavaScript",
              "Node.js",
              "HTML5",
              "CSS3",
              "Ruby",
              "Python",
              "SQL",
              "Java",
              "C#",
              "C",
              "C++"
          ],
          "libraries": [
              "Ruby on Rails",
              "Laravel",
              "Express",
              "React",
              "Bootstrap",
              "jQuery",
              "Angular",
              "Spring",
              ".NET",
              "Flask",
              "Django"
          ],
          "other": [
              "Git",
              "MySQL",
              "MariaDB",
              "PostgreSQL",
              "MongoDB",
              "Firebase",
              "AWS"
          ]
      }
  },
  "Alvin Ng": {
      "email": "alvin.cl.ng@gmail.com",
      "discordID": "338560570234109952",
      "yearsOfExperience": 8,
      "titles": [
          "Developer",
          "Senior Front End Developer",
          "Instructor",
          "Years of Industry"
      ],
      "skills": {
          "languages": [
              "PHP",
              "JavaScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "SQL"
          ],
          "libraries": [
              "Ruby on Rails",
              "WordPress",
              "Express",
              "React",
              "Bootstrap",
              "jQuery",
              "Next.JS",
              "Vue",
              "ThreeJS",
              "TailwindCSS"
          ],
          "other": [
              "Git",
              "MySQL",
              "MongoDB",
              "PostgreSQL"
          ]
      }
  },
  "Andy Lindsay": {
      "email": "andy.lindsay@lighthouselabs.com",
      "discordID": "408843400284995584",
      "yearsOfExperience": 10,
      "titles": [],
      "skills": {
          "languages": [
              "HTML5",
              "CSS3",
              "JavaScript",
              "SQL",
              "Ruby",
              "SCSS",
              "TypeScript"
          ],
          "libraries": [
              "Ruby on Rails",
              "Express",
              "React",
              "Bootstrap",
              "jQuery",
              "Vue"
          ],
          "other": [
              "Git",
              "PostgreSQL",
              "MySQL",
              "MongoDB",
              "Microsoft Access",
              "AWS",
              "Azure"
          ]
      }
  },
  "Beatriz Oliveira": {
      "email": "beaolivei@gmail.com",
      "discordID": "998678005171884193",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Bryan Gomes": {
      "email": "bryanpgomes@gmail.com",
      "discordID": "989693253454553088",
      "yearsOfExperience": 4,
      "titles": [
          "Software Developer",
          "Mentor",
          "Instructor",
          "SME"
      ],
      "skills": {
          "languages": [
              "TypeScript",
              "HTML5",
              "CSS3",
              "JavaScript",
              "Ruby",
              "SQL",
              "Java"
          ],
          "libraries": [
              "React",
              "Next.JS",
              "Ruby on Rails",
              "Express",
              "jQuery",
              "SASS",
              "MaterialUI",
              "React Native",
              "Selenium",
              "Cypress",
              "Sanity"
          ],
          "other": [
              "Google Cloud",
              "Git",
              "PostgreSQL",
              "Firebase",
              "Storybook",
              "GROQ"
          ]
      }
  },
  "Cheng Shi": {
      "email": "cshi@ualberta.ca",
      "discordID": "989633283111395328",
      "yearsOfExperience": 5,
      "titles": [
          "Software Developer (AI\/ML focus)",
          "Certified Google Cloud Engineer",
          "Instructor"
      ],
      "skills": {
          "languages": [
              "Python",
              "JavaScript",
              "TypeScript",
              "PHP",
              "Ruby",
              "SQL"
          ],
          "libraries": [
              "Angular",
              "React",
              "Vue",
              "Flask",
              "Laravel",
              "Rails",
              "Express"
          ],
          "other": [
              "Google Cloud (Mostly BigQuery, Cloud SQL, GKE, Compute Engine, Vertex AI)",
              "GraphQL"
          ]
      }
  },
  "Chetna Robinson": {
      "email": "chetna.robinson@lighthouselabs.com",
      "discordID": "748880412830334976",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Christian Nally": {
      "email": "christian.nally@lighthouselabs.com",
      "discordID": "784266118411190282",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "David Halpin": {
      "email": "devhalpin86@gmail.com",
      "discordID": "208351147960958976",
      "yearsOfExperience": 4,
      "titles": [
          "Software Developer",
          "Mentor",
          "Instructor",
          "SME"
      ],
      "skills": {
          "languages": [
              "TypeScript",
              "HTML5",
              "CSS3",
              "Javascript",
              "Ruby",
              "SQL",
              "Java",
              "C#",
              "Rust"
          ],
          "libraries": [
              "React",
              "Next.JS",
              "Ruby on Rails",
              "Express",
              "jQuery",
              "SASS",
              "MaterialUI",
              "React Native",
              "Selenium",
              "Cypress",
              "Sanity",
              "TailwindCSS"
          ],
          "other": [
              "Google Cloud",
              "Git",
              "PostgreSQL",
              "Firebase",
              "Storybook",
              "GraphQL"
          ]
      }
  },
  "Dominic Tremblay": {
      "email": "dominictremblay24@gmail.com",
      "discordID": "690424009883648000",
      "yearsOfExperience": 20,
      "titles": [
          "Freelance Developer",
          "Tech Educator"
      ],
      "skills": {
          "languages": [
              "HTML",
              "CSS",
              "JavaScript",
              "TypeScript",
              "Python",
              "Ruby",
              "SQL",
              "NoSQL",
              "AppScript"
          ],
          "libraries": [
              "NodeJS",
              "Express",
              "Prisma",
              "Ruby on Rails",
              "Sinatra",
              "Django",
              "React",
              "Next.JS",
              "jQuery",
              "Astro"
          ],
          "other": [
              "BigQuery",
              "Data Analytics",
              "Instructional Design",
              "Career Coaching"
          ]
      }
  },
  "Duy Tran": {
      "email": "duyatran1994@gmail.com",
      "discordID": "856092304057696268",
      "yearsOfExperience": 5,
      "titles": [
          "Software Developer",
          "Software Engineer",
          "Instructor",
          "Mentor"
      ],
      "skills": {
          "languages": [
              "C",
              "C++",
              "JavaScript",
              "Python",
              "Ruby",
              "Java",
              "Go",
              "SQL"
          ],
          "libraries": [
              "Ruby on Rails",
              "Spring Boot",
              "Spring Cloud",
              "React",
              "Vue",
              "AngularJS 1.x"
          ],
          "other": [
              "Git",
              "PostgreSQL",
              "Oracle",
              "Docker",
              "Kafka"
          ]
      }
  },
  "Eric Murray": {
      "email": "ermurray31@gmail.com",
      "discordID": "425009158140592128",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Francis Bourgouin": {
      "email": "francis.bourgouin@lighthouselabs.com",
      "discordID": "281611107787997195",
      "yearsOfExperience": 15,
      "titles": [
          "Developer",
          "Consultant",
          "Instructor",
          "Lead Instructor"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "TypeScript",
              "PHP",
              "Ruby",
              "Go",
              "CSS",
              "HTML"
          ],
          "libraries": [
              "Ruby on Rails",
              "WordPress",
              "Express",
              "React",
              "NextJS",
              "Tailwind",
              "Bootstrap",
              "jQuery",
              "ThreeJS",
              "Framer",
              "GSAP"
          ],
          "other": [
              "Git",
              "MySQL",
              "MariaDB",
              "PostgreSQL"
          ]
      }
  },
  "Garret Brick": {
      "email": "garrettgsb@gmail.com",
      "discordID": "355054177455439873",
      "yearsOfExperience": 7,
      "titles": [
          "Software Engineer"
      ],
      "skills": {
          "languages": [
              "Typescript",
              "JavaScript",
              "Ruby",
              "SQL",
              "CSS",
              "Python",
              "Golang",
              "C#",
              "C",
              "C++"
          ],
          "libraries": [
              "React",
              "Ruby on Rails",
              "Express",
              "NextJS"
          ],
          "other": [
              "Arduino\/microcontrollers",
              "Docker",
              "AWS",
              "Unity",
              "Blender"
          ]
      }
  },
  "Gary Jipp": {
      "email": "lighthouse@accu.ca",
      "discordID": "989629736714108989",
      "yearsOfExperience": 37,
      "titles": [
          "Senior Technical Architect",
          "Solutions Architect"
      ],
      "skills": {
          "languages": [
              "Java",
              "JavaScript",
              "Kotlin",
              "C",
              "C++",
              "C#",
              "PHP",
              "SQL",
              "Python Assembly",
              "PL1"
          ],
          "libraries": [
              "JavaEE",
              "Express",
              "React",
              "React Native",
              "Vue",
              "Cypress",
              "Android Native"
          ],
          "other": [
              "Docker\/Podman",
              "OpenShift",
              "GitHub Actions",
              "OAuth2",
              "Asterisk"
          ]
      }
  },
  "Ian Bentley": {
      "email": "ian@idbentley.com",
      "discordID": "702690556731719690",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Jake Robins": {
      "email": "mendahu@gmail.com",
      "discordID": "274725911415226369",
      "yearsOfExperience": 4,
      "titles": [
          "Developer",
          "Freelance Developer",
          "Instructor"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "TypeScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "Go",
              "SQL"
          ],
          "libraries": [
              "Next.JS",
              "Nest.JS",
              "Ruby on Rails",
              "Express",
              "React",
              "Bootstrap",
              "jQuery",
              "TailwindCSS"
          ],
          "other": [
              "Git",
              "MySQL",
              "PostgreSQL"
          ]
      }
  },
  "Jimmy Peng": {
      "email": "jimmyp22@gmail.com",
      "discordID": "549312663469948942",
      "yearsOfExperience": 4,
      "titles": [
          "Senior (Backend) Developer"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "TypeScript",
              "Ruby",
              "SQL"
          ],
          "libraries": [
              "React",
              "Rails",
              "Express"
          ],
          "other": [
              "Git",
              "Linux",
              "GraphQL",
              "Monitoring"
          ]
      }
  },
  "Jon Barson": {
      "email": "jbarson@gmail.com",
      "discordID": "172349365875245056",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Julien Atanassian": {
      "email": "julien.atanassian@gmail.com",
      "discordID": "232122356670267392",
      "yearsOfExperience": 4,
      "titles": [
          "Full Stack Developer",
          "Instructor",
          "Mentor"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "TypeScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "Python"
          ],
          "libraries": [
              "Vue",
              "React",
              "Nuxt",
              "Next.JS",
              "Ruby on Rails",
              "Express",
              "Bootstrap",
              "TailwindCSS",
              "jQuery",
              "Angular",
              "Sequelize"
          ],
          "other": [
              "Git",
              "PostgreSQL",
              "Firebase"
          ]
      }
  },
  "Khurram Virani": {
      "email": "kvirani@lighthouselabs.ca",
      "discordID": "954431477540618270",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Mathius Johnson": {
      "email": "yogimathius@gmail.com",
      "discordID": "491820454676398091",
      "yearsOfExperience": 4,
      "titles": [
          "Software Developer",
          "Instructor",
          "Mentor"
      ],
      "skills": {
          "languages": [
              "Typescript",
              "JavaScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "Python",
              "SQL",
              "C",
              "Rust"
          ],
          "libraries": [
              "Ruby on Rails",
              "Express",
              "React",
              "Bootstrap",
              "TailwindCSS",
              "SASS",
              "jQuery",
              "Angular",
              "NestJS",
              "Remix",
              "Django",
              "Flask",
              "TypeORM",
              "Prisma"
          ],
          "other": [
              "Git",
              "MySQL",
              "MongoDB",
              "PostgreSQL",
              "GraphQL",
              "GCP",
              "AWS"
          ]
      }
  },
  "Mauricio Saavedra": {
      "email": "mauricio.saavi@gmail.com",
      "discordID": "423708581746180117",
      "yearsOfExperience": 6,
      "titles": [
          "Software Engineer",
          "Android Developer",
          "Fullstack Developer",
          "Tech Lead",
          "Director of Operations",
          "Director of Education and Academy",
          "Web Development Mentor",
          "Web Development Instructor"
      ],
      "skills": {
          "languages": [
              "Java",
              "Kotlin (Android Dev)",
              "C#",
              "PHP",
              "Python",
              "JavaScript",
              "Node.js",
              "HTML5",
              "CSS3",
              "Ruby",
              "MongoDB",
              "Neo4j",
              "SQL"
          ],
          "libraries": [
              "Spring MVC",
              "Vue",
              "React",
              "Redux",
              "Remix",
              "Gatsby",
              "Next.JS",
              "Django",
              "Ruby on Rails",
              "WordPress",
              "Laravel",
              "Express",
              "Bootstrap",
              "Foundation",
              "Materialize",
              "MaterialUI",
              "jQuery"
          ],
          "other": [
              "Figma",
              "Netlify",
              "Heroku",
              "Firebase",
              "GCP",
              "CI\/CD Pipelines w\/Github Actions",
              "Git",
              "Gitlab",
              "Jenkins",
              "Docker",
              "Unity"
          ]
      }
  },
  "Pedro Gonzalez": {
      "email": "pgonzalez@post.com",
      "discordID": "561630031529377801",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Rohit Dhand": {
      "email": "dhandrohit@hotmail.com",
      "discordID": "989620874497896508",
      "yearsOfExperience": 20,
      "titles": [
          "Principal Solutions Architect",
          "Cloud Solutions Architect",
          "Assistant Director",
          "Lead Enterprise Architect",
          "Cloud Data Architect"
      ],
      "skills": {
          "languages": [
              "C#",
              "Nodejs",
              "SQL",
              "Python",
              "HTML",
              "C++",
              "Java",
              "Ruby"
          ],
          "libraries": [
              "React",
              "Ruby on Rails",
              ".NET Core",
              "ASP.NET Core",
              "jQuery"
          ],
          "other": [
              "Azure Data Bricks",
              "SnowFlake",
              "Azure Data Factory",
              "SSIS",
              "DatawareHousing",
              "Data Lakes",
              "DataLakehouse Pattern",
              "Azure",
              "AWS Cloud",
              "GCP"
          ]
      }
  },
  "Rony Kordahi": {
      "email": "kordrony@gmail.com",
      "discordID": "85534406369894400",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Ryan Ternier": {
      "email": "ryan_ternier@hotmail.com",
      "discordID": "288153386594074626",
      "yearsOfExperience": 21,
      "titles": [
          "Director of Technology",
          "Portfolio Architect"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "SQL",
              "C#",
              ".NET",
              "Java",
              "HTML"
          ],
          "libraries": [
              "Angular",
              "React",
              "Vue",
              "Express"
          ],
          "other": [
              "Git",
              "Subversion",
              "Cyber Security",
              "Azure",
              "AWS",
              "Katalon",
              "Docker",
              "Openshift"
          ]
      }
  },
  "Sakhia Kwemo": {
      "email": "kwemos@gmail.com",
      "discordID": "904209580001726504",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Sandeep Chopra": {
      "email": "sandeepchopra7@gmail.com",
      "discordID": "712027125657370684",
      "yearsOfExperience": 4,
      "titles": [
          "Software Developer \/ Engineer",
          "Team Lead - Frontend\/Fullstack",
          "Acting Tech Lead",
          "Frontend Tech Lead"
      ],
      "skills": {
          "languages": [
              "Typescript",
              "HTML5",
              "CSS3",
              "C#",
              "Ruby"
          ],
          "libraries": [
              "Next.JS",
              "Ruby on Rails",
              "Express",
              "React",
              "jQuery",
              "Angular",
              ".NET",
              "TailwindCSS",
              "SPA",
              "MaterialUI"
          ],
          "other": [
              "AWS Cloud",
              "Google Cloud",
              "Git",
              "PostgreSQL",
              "Storybook",
              "GraphQL",
              "TurboRepo"
          ]
      }
  },
  "Steve Abouem": {
      "email": "steveabouem@gmail.com",
      "discordID": "974011254651908196",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Taiwo Oyeniyi": {
      "email": "muyiwaoyeniyi@gmail.com",
      "discordID": "953023075371331654",
      "yearsOfExperience": 10,
      "titles": [
          "Software Developer",
          "Senior Software Engineer",
          "CTO",
          "Instructor"
      ],
      "skills": {
          "languages": [
              "JavaScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "SQL"
          ],
          "libraries": [
              "Next.JS",
              "Ruby on Rails",
              "Express",
              "React",
              "Bootstrap",
              "jQuery"
          ],
          "other": [
              "Git",
              "MySQL",
              "PostgreSQL"
          ]
      }
  },
  "Vasiliy Klimkin": {
      "email": "vasily.klimkin@gmail.com",
      "discordID": "253312489683550209",
      "yearsOfExperience": 0,
      "titles": [],
      "skills": {
          "languages": [],
          "libraries": [],
          "other": []
      }
  },
  "Warren Uhrich": {
      "email": "warren.uhrich@lighthouselabs.com",
      "discordID": "975851887016439808",
      "yearsOfExperience": 10,
      "titles": [
          "Developer",
          "Team Lead",
          "Senior Developer",
          "Instructor",
          "Lead Instructor",
          "Flex Lead"
      ],
      "skills": {
          "languages": [
              "PHP",
              "JavaScript",
              "HTML5",
              "CSS3",
              "Ruby",
              "Python",
              "SQL"
          ],
          "libraries": [
              "Ruby on Rails",
              "WordPress",
              "Laravel",
              "Express",
              "React",
              "Bootstrap",
              "jQuery"
          ],
          "other": [
              "Git",
              "MySQL",
              "MariaDB",
              "PostgreSQL"
          ]
      }
  }
}

export const mentorSeedQuery = `
INSERT INTO 
  mentors (mentor_clocktower_id, name, email, discord_id, years_of_experience, titles, language_skills, library_skills, other_skills )
VALUES 
  (?, ?, ?, ?, ?, ?, ?, ?, ?)
`;

export const parsedMentorSeed = Object.entries(mentorSeedData).map(([name, mentor]) => ({
  sql:mentorSeedQuery,
  args: [
    "mclockid-"+mentor.discordID,
    name,
    mentor.email,
    mentor.discordID,
    mentor.yearsOfExperience,
    JSON.stringify(mentor.titles),
    JSON.stringify(mentor.skills.languages),
    JSON.stringify(mentor.skills.libraries),
    JSON.stringify(mentor.skills.other),
  ]
}))