import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function NoteForm({ updateShiftNotes, selectedShift }) {
  const initialFormData = {
    note: selectedShift.note || "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (event) => {
    const { value, name } = event.target;


    return setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateShiftNotes(formData.note);
  };

  return (
    <Form className="d-flex flex-column align-content-center justify-content-center flex-shrink-1" onSubmit={handleSubmit}>
      <p>Update note from this shift</p>
      <Form.Control as="textarea" rows={3} value={formData.note} onChange={handleChange} name="note"/>
      <Button variant="primary" type="submit">
        Update Note
      </Button>
    </Form>
  );
}

export default NoteForm;
