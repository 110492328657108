// type meeting = {
//   id: string;
//   name: string;
//   start: string;
//   end: string;
// };

const getDateTime = (dateString) => new Date(dateString).getTime();

const checkMatchingOverlaps = (meeting1, meeting2) =>
  meeting1.length === meeting2.length &&
  meeting1.length === meeting1.filter((e) => meeting2.includes(e)).length;

const checkMeetingOverlap = (meeting1, meeting2) => {
  const meetingDates = {
    meeting1Start: getDateTime(meeting1.start),
    meeting1End: getDateTime(meeting1.end),
    meeting2Start: getDateTime(meeting2.start),
    meeting2End: getDateTime(meeting2.end),
  };

  if (meetingDates.meeting1Start === meetingDates.meeting2Start) {
    return true;
  }

  if (
    meetingDates.meeting1Start > meetingDates.meeting2Start &&
    meetingDates.meeting1Start < meetingDates.meeting2End
  ) {
    return true;
  }

  if (
    meetingDates.meeting1Start < meetingDates.meeting2Start &&
    meetingDates.meeting1End > meetingDates.meeting2Start
  ) {
    return true;
  }

  return false;
};

const listMeetingOverlaps = (meetings) => {
  // const output = { overlaps: {}, meetingUsed: [] };
  const output = {};

  for (const meeting of meetings) {
    output[meeting.id] = [];
  }

  for (const meeting of meetings) {
    for (const meeting2 of meetings) {
      if (meeting !== meeting2 && checkMeetingOverlap(meeting, meeting2)) {
        output[meeting.id].push(meeting2.id);
      }
    }
  }

  return output;
};

const stackOverlaps = (overlapInfo) => {
  const output = {};

  for (const key in overlapInfo) {
    const meetings = overlapInfo[key];
    const subOutput = [key];
    for (const meetingKey of meetings) {
      let trigger = true;

      for (const storedKey of subOutput) {
        if (!overlapInfo[meetingKey].includes(storedKey)) {
          trigger = false;
        }
      }

      if (trigger) {
        subOutput.push(meetingKey);
      }
    }

    output[key] = { overlaps: subOutput.sort(), maxOverlap: 1 };
  }

  for (const key in overlapInfo) {
    for (const obj of Object.values(output)) {
      if (obj.overlaps.includes(key) && obj.overlaps.length > output[key].maxOverlap) {
        output[key].maxOverlap = obj.overlaps.length;
      }
    }
  }

  for (const key1 in overlapInfo) {
    const meetings = output[key1].overlaps;

    for (const meetingId of meetings) {
      output[meetingId].overlaps = meetings;
    }
  }

  return output;
};

export const generateMeetingOverlapInfo = (meetings) => {
  const result = listMeetingOverlaps(meetings);
  const result2 = stackOverlaps(result);

  return result2;
};

export const formatDateForInput = (currentDay) => {
  const year = currentDay.getFullYear();
  const month = ("0" + (currentDay.getMonth() + 1)).slice(-2);
  const day = ("0" + currentDay.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
};
