export const getOffsetFromUTC = (timeZone) => {
  const date = new Date();
  const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));

  return (tzDate.getTime() - utcDate.getTime()) / 6e4 / 60;
};

const offsettedDate = (initialDate, targetTimezone) => {
  const offset = getOffsetFromUTC("America/Los_Angeles");
  const newDate = new Date(initialDate);

  const targetDate = new Date(
    newDate.toLocaleString("en-US", { timeZone: targetTimezone })
  );
  targetDate.setHours(targetDate.getHours() - offset);

  return targetDate;
};

export default offsettedDate;

// Which week you're in - weekOf
// Day of the week (english) - dow
// const daysOfWeek = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"]
// const dayOffset = daysOfWeek.indexOf(dow)
// weekOf + dayOffset : startHour:00:00 PT
// "2024-05-15 13:00:00 PT"
// Local datetime

// Shift start hour PT
