import React, { useState } from "react";
import {addDays, isBefore, isAfter } from "date-fns"

export default function LectureList(props) {
  const { user, conferences, instructors, programs, allConferences } = props;
  const [isUserOnly, setIsUserOnly] = useState(true);

  const maxDays = 45
  const todayDate = new Date()
  const maxDate = addDays(todayDate, maxDays)

  const filteredConferences = conferences
    .filter((conf) => (isUserOnly ? String(user.id) === conf.mentor_clocktower_id : true))
    .filter(conf => isAfter(new Date(conf.start_datetime), todayDate ))
    .filter(conf => isAfter(maxDate, new Date(conf.start_datetime) ))
    .sort((a, b) => new Date(a.start_datetime) - new Date(b.start_datetime));

  const parsedConferences = filteredConferences.map((conf) => {
    const rowClass = String(user.id) === conf.mentor_clocktower_id ? "current" : "";
    const program = programs.find((program) =>
      program.activeAndUpcomingCohortUuids.includes(conf.cohort_uuid)
    );
    const cohort = program?.activeCohorts.find(
      (cohort) => cohort.uuid === conf.cohort_uuid
    );

    const week_label = allConferences.find(originalConf => originalConf.conference_uuid === conf.conference_uuid)?.week_label
    return (
      <tr className={rowClass} key={conf.conference_uuid+"-"+conf.cohort_uuid}>
        <td>{new Date(conf.start_datetime).toLocaleDateString()}</td>
        {week_label && <td>{week_label} - {conf.name}</td>}
        {!week_label && <td>{conf.name}</td>}
        <td>
          {
            instructors.find(
              (inst) => inst.mentor_clocktower_id === conf.mentor_clocktower_id
            )?.name
          }
        </td>
        <td>{cohort?.name}</td>
        <td>{program?.name}</td>
      </tr>
    );
  });
  return (
    <section className="LectureList">
      <header>
        <h1>
          <span>Upcoming lectures in all programs</span>
        </h1>
        <button onClick={() => setIsUserOnly(!isUserOnly)}>
          {isUserOnly ? "View all lectures" : "View my lectures"}
        </button>
      </header>
      <table>
        <thead>
          <tr>
            <td>Date</td>
            <td>Lecture Name</td>
            <td>Instructor</td>
            <td>Cohort</td>
            <td>Program</td>
          </tr>
        </thead>
        <tbody>{parsedConferences}</tbody>
      </table>
    </section>
  );
}