import React from "react";
// type Meeting = {
//   id: string;
//   name: string;
//   start: string;
//   end: string;
// };

// type CalendarProps = {
//   /** Days to show using day integers (Sun/Sat) (0-6) */
//   daysToShow: number[];
//   /** Starting hour in UTC time */
//   hourStart: number;
//   amountOfHours: number;
//   date?: string;
//   meetings: Meeting[];
// };

import { useMemo } from "react";
import {
  startOfWeek,
  isWithinInterval,
  endOfWeek,
  startOfMonth,
  endOfMonth,
} from "date-fns";

import { generateMeetingOverlapInfo } from "../../helpers/dateHelpers";

import useCalendar from "./useCalendar";
import WeekView from "./WeekView";
import MonthView from "./MonthView";

export default function Calendar(props) {
  const { hourStart, amountOfHours, events, date, daysToShow } = props;

  const { week, month, currentDay, viewMode, toggleViewMode } = useCalendar({
    date,
    hourStart,
    amountOfHours,
    daysToShow,
  });

  const overlaps = useMemo(() => generateMeetingOverlapInfo(events), [events]);

  const filteredEvents = useMemo(
    () =>
      events.filter((event) => {
        const eventDate = new Date(event.start);

        if (viewMode === "WEEK") {
          return isWithinInterval(eventDate, {
            start: startOfWeek(currentDay),
            end: endOfWeek(currentDay),
          });
        }
        if (viewMode === "MONTH") {
          return isWithinInterval(eventDate, {
            start: startOfMonth(currentDay),
            end: endOfMonth(currentDay),
          });
        }
      }),
    [viewMode, currentDay, events]
  );

  // const lecturersByDay = filteredEvents.reduce((acc, event) => {
  //   if (acc[event.day]) {
  //     acc[event.day].push(event.lecturerId);
  //   } else {
  //     acc[event.day] = [event.lecturerId];
  //   }
  //   return acc;
  // }, {});

  const parsedEventData = filteredEvents.map((meeting) => ({
    ...meeting,
    key: meeting.id,
    overlapInfo: overlaps[meeting.id],
  }));

  return (
    <section className={`Calendar ${viewMode.toLocaleLowerCase()}`}>
      {viewMode === "WEEK" && (
        <WeekView
          {...week}
          currentDay={currentDay}
          events={parsedEventData}
          toggleViewMode={toggleViewMode}
        />
      )}

      {viewMode === "MONTH" && (
        <MonthView
          {...month}
          currentDay={currentDay}
          events={parsedEventData}
          toggleViewMode={toggleViewMode}
        />
      )}
    </section>
  );
}
