export const mentorShifts = [
  {
    id: 424851,
    user_id: "2848",
    title: "Hope Warren",
    start: "2024-04-22T09:00:00.000+00:00",
    end: "2024-04-22T10:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 424852,
    user_id: "2757",
    title: "Anjali Kabariya",
    start: "2024-04-22T09:00:00.000+00:00",
    end: "2024-04-22T10:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 424901,
    user_id: "2514",
    title: "Austin Alozie",
    start: "2024-04-22T14:00:00.000+00:00",
    end: "2024-04-22T15:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 424902,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-22T14:00:00.000+00:00",
    end: "2024-04-22T15:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 424909,
    user_id: "2850",
    title: "Olawale  Ajuwon",
    start: "2024-04-22T15:00:00.000+00:00",
    end: "2024-04-22T16:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 424911,
    user_id: "1705",
    title: "Andy Lindsay",
    start: "2024-04-22T15:00:00.000+00:00",
    end: "2024-04-22T16:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425440,
    user_id: "2481",
    title: "MD RAJIB-UL ISLAM",
    start: "2024-04-27T14:00:00.000+00:00",
    end: "2024-04-27T15:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425441,
    user_id: "2497",
    title: "Wakeel Ogunsanya",
    start: "2024-04-27T15:00:00.000+00:00",
    end: "2024-04-27T16:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425445,
    user_id: "2481",
    title: "MD RAJIB-UL ISLAM",
    start: "2024-04-27T15:00:00.000+00:00",
    end: "2024-04-27T16:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425448,
    user_id: "2713",
    title: "Anh Duy Tran",
    start: "2024-04-28T09:00:00.000+00:00",
    end: "2024-04-28T10:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425450,
    user_id: "2713",
    title: "Anh Duy Tran",
    start: "2024-04-28T10:00:00.000+00:00",
    end: "2024-04-28T11:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425454,
    user_id: "2771",
    title: "Yashsvi Girdhar",
    start: "2024-04-28T12:00:00.000+00:00",
    end: "2024-04-28T13:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425456,
    user_id: "2549",
    title: "Nathan McKenzie",
    start: "2024-04-28T12:00:00.000+00:00",
    end: "2024-04-28T13:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425457,
    user_id: "2771",
    title: "Yashsvi Girdhar",
    start: "2024-04-28T13:00:00.000+00:00",
    end: "2024-04-28T14:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425458,
    user_id: "2549",
    title: "Nathan McKenzie",
    start: "2024-04-28T13:00:00.000+00:00",
    end: "2024-04-28T14:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425467,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-25T14:00:00.000+00:00",
    end: "2024-04-25T15:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web TI",
  },
  {
    id: 425482,
    user_id: "2741",
    title: "Matthew Tran",
    start: "2024-04-27T15:00:00.000+00:00",
    end: "2024-04-27T16:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425483,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-28T09:00:00.000+00:00",
    end: "2024-04-28T10:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425489,
    user_id: "2549",
    title: "Nathan McKenzie",
    start: "2024-04-28T15:00:00.000+00:00",
    end: "2024-04-28T16:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425362,
    user_id: "2454",
    title: "Lucas Kitteridge",
    start: "2024-04-26T16:00:00.000+00:00",
    end: "2024-04-26T17:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425365,
    user_id: "2414",
    title: "Bradley Mark",
    start: "2024-04-26T16:00:00.000+00:00",
    end: "2024-04-26T17:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425366,
    user_id: "2673",
    title: "Ankur Bag",
    start: "2024-04-26T17:00:00.000+00:00",
    end: "2024-04-26T18:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425369,
    user_id: "2454",
    title: "Lucas Kitteridge",
    start: "2024-04-26T17:00:00.000+00:00",
    end: "2024-04-26T18:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425418,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-27T11:00:00.000+00:00",
    end: "2024-04-27T12:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425421,
    user_id: "2530",
    title: "Brooks Dulla",
    start: "2024-04-27T11:00:00.000+00:00",
    end: "2024-04-27T12:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425424,
    user_id: "2691",
    title: "Juliana Ifionu",
    start: "2024-04-27T12:00:00.000+00:00",
    end: "2024-04-27T13:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425425,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-27T12:00:00.000+00:00",
    end: "2024-04-27T13:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425428,
    user_id: "2530",
    title: "Brooks Dulla",
    start: "2024-04-27T12:00:00.000+00:00",
    end: "2024-04-27T13:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425431,
    user_id: "2691",
    title: "Juliana Ifionu",
    start: "2024-04-27T13:00:00.000+00:00",
    end: "2024-04-27T14:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425434,
    user_id: "2481",
    title: "MD RAJIB-UL ISLAM",
    start: "2024-04-27T13:00:00.000+00:00",
    end: "2024-04-27T14:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425436,
    user_id: "2497",
    title: "Wakeel Ogunsanya",
    start: "2024-04-27T14:00:00.000+00:00",
    end: "2024-04-27T15:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425486,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-28T12:00:00.000+00:00",
    end: "2024-04-28T13:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425487,
    user_id: "2713",
    title: "Anh Duy Tran",
    start: "2024-04-28T11:00:00.000+00:00",
    end: "2024-04-28T12:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425488,
    user_id: "2549",
    title: "Nathan McKenzie",
    start: "2024-04-28T14:00:00.000+00:00",
    end: "2024-04-28T15:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425459,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-28T14:00:00.000+00:00",
    end: "2024-04-28T15:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425461,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-28T15:00:00.000+00:00",
    end: "2024-04-28T16:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 424860,
    user_id: "2600",
    title: "Julian Bustos",
    start: "2024-04-22T10:00:00.000+00:00",
    end: "2024-04-22T11:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 424862,
    user_id: "2848",
    title: "Hope Warren",
    start: "2024-04-22T10:00:00.000+00:00",
    end: "2024-04-22T11:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 424864,
    user_id: "2757",
    title: "Anjali Kabariya",
    start: "2024-04-22T10:00:00.000+00:00",
    end: "2024-04-22T11:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 424871,
    user_id: "2600",
    title: "Julian Bustos",
    start: "2024-04-22T11:00:00.000+00:00",
    end: "2024-04-22T12:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 424873,
    user_id: "2848",
    title: "Hope Warren",
    start: "2024-04-22T11:00:00.000+00:00",
    end: "2024-04-22T12:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 424876,
    user_id: "2757",
    title: "Anjali Kabariya",
    start: "2024-04-22T11:00:00.000+00:00",
    end: "2024-04-22T12:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 424883,
    user_id: "2600",
    title: "Julian Bustos",
    start: "2024-04-22T12:00:00.000+00:00",
    end: "2024-04-22T13:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 424892,
    user_id: "2514",
    title: "Austin Alozie",
    start: "2024-04-22T13:00:00.000+00:00",
    end: "2024-04-22T14:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 424893,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-22T13:00:00.000+00:00",
    end: "2024-04-22T14:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 424896,
    user_id: "2593",
    title: "Warren Uhrich",
    start: "2024-04-22T14:00:00.000+00:00",
    end: "2024-04-22T15:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 424917,
    user_id: "2850",
    title: "Olawale  Ajuwon",
    start: "2024-04-22T16:00:00.000+00:00",
    end: "2024-04-22T17:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 424918,
    user_id: "1705",
    title: "Andy Lindsay",
    start: "2024-04-22T16:00:00.000+00:00",
    end: "2024-04-22T17:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 424927,
    user_id: "2850",
    title: "Olawale  Ajuwon",
    start: "2024-04-22T17:00:00.000+00:00",
    end: "2024-04-22T18:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 424932,
    user_id: "2305",
    title: "Sangyoup Bae",
    start: "2024-04-22T17:00:00.000+00:00",
    end: "2024-04-22T18:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 424934,
    user_id: "2748",
    title: "Ricardo Wagner Gomes",
    start: "2024-04-22T18:00:00.000+00:00",
    end: "2024-04-22T19:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 424940,
    user_id: "2305",
    title: "Sangyoup Bae",
    start: "2024-04-22T18:00:00.000+00:00",
    end: "2024-04-22T19:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 424944,
    user_id: "2748",
    title: "Ricardo Wagner Gomes",
    start: "2024-04-22T19:00:00.000+00:00",
    end: "2024-04-22T20:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 424952,
    user_id: "2305",
    title: "Sangyoup Bae",
    start: "2024-04-22T19:00:00.000+00:00",
    end: "2024-04-22T20:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 424955,
    user_id: "2748",
    title: "Ricardo Wagner Gomes",
    start: "2024-04-22T20:00:00.000+00:00",
    end: "2024-04-22T21:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 424961,
    user_id: "2738",
    title: "Osagie Omoruyi",
    start: "2024-04-23T09:00:00.000+00:00",
    end: "2024-04-23T10:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 424970,
    user_id: "2738",
    title: "Osagie Omoruyi",
    start: "2024-04-23T10:00:00.000+00:00",
    end: "2024-04-23T11:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 424979,
    user_id: "1654",
    title: "Travis Borsa",
    start: "2024-04-23T11:00:00.000+00:00",
    end: "2024-04-23T12:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 424981,
    user_id: "2738",
    title: "Osagie Omoruyi",
    start: "2024-04-23T11:00:00.000+00:00",
    end: "2024-04-23T12:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 424989,
    user_id: "1654",
    title: "Travis Borsa",
    start: "2024-04-23T12:00:00.000+00:00",
    end: "2024-04-23T13:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 424990,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-23T12:00:00.000+00:00",
    end: "2024-04-23T13:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 424999,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-23T13:00:00.000+00:00",
    end: "2024-04-23T14:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425006,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-23T14:00:00.000+00:00",
    end: "2024-04-23T15:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425008,
    user_id: "2593",
    title: "Warren Uhrich",
    start: "2024-04-23T14:00:00.000+00:00",
    end: "2024-04-23T15:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425017,
    user_id: "2692",
    title: "Julia  Di Monte",
    start: "2024-04-23T15:00:00.000+00:00",
    end: "2024-04-23T16:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425021,
    user_id: "1705",
    title: "Andy Lindsay",
    start: "2024-04-23T15:00:00.000+00:00",
    end: "2024-04-23T16:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425026,
    user_id: "2692",
    title: "Julia  Di Monte",
    start: "2024-04-23T16:00:00.000+00:00",
    end: "2024-04-23T17:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425029,
    user_id: "1705",
    title: "Andy Lindsay",
    start: "2024-04-23T16:00:00.000+00:00",
    end: "2024-04-23T17:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425034,
    user_id: "2453",
    title: "Him Wo John Wong",
    start: "2024-04-23T17:00:00.000+00:00",
    end: "2024-04-23T18:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425038,
    user_id: "2692",
    title: "Julia  Di Monte",
    start: "2024-04-23T17:00:00.000+00:00",
    end: "2024-04-23T18:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425042,
    user_id: "2573",
    title: "Johnny Labedzki",
    start: "2024-04-23T17:00:00.000+00:00",
    end: "2024-04-23T18:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425043,
    user_id: "2453",
    title: "Him Wo John Wong",
    start: "2024-04-23T18:00:00.000+00:00",
    end: "2024-04-23T19:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425051,
    user_id: "2573",
    title: "Johnny Labedzki",
    start: "2024-04-23T18:00:00.000+00:00",
    end: "2024-04-23T19:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425054,
    user_id: "2453",
    title: "Him Wo John Wong",
    start: "2024-04-23T19:00:00.000+00:00",
    end: "2024-04-23T20:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425062,
    user_id: "2737",
    title: "Anuradha Katti",
    start: "2024-04-23T19:00:00.000+00:00",
    end: "2024-04-23T20:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425088,
    user_id: "1324",
    title: "Dominique Carmel-Tremblay",
    start: "2024-04-24T10:00:00.000+00:00",
    end: "2024-04-24T11:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425093,
    user_id: "2846",
    title: "Ana Franco",
    start: "2024-04-24T11:00:00.000+00:00",
    end: "2024-04-24T12:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425096,
    user_id: "1324",
    title: "Dominique Carmel-Tremblay",
    start: "2024-04-24T11:00:00.000+00:00",
    end: "2024-04-24T12:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425103,
    user_id: "2846",
    title: "Ana Franco",
    start: "2024-04-24T12:00:00.000+00:00",
    end: "2024-04-24T13:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425105,
    user_id: "2659",
    title: "Mike Niu",
    start: "2024-04-24T12:00:00.000+00:00",
    end: "2024-04-24T13:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425113,
    user_id: "2659",
    title: "Mike Niu",
    start: "2024-04-24T13:00:00.000+00:00",
    end: "2024-04-24T14:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425114,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-24T13:00:00.000+00:00",
    end: "2024-04-24T14:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425149,
    user_id: "2445",
    title: "Yash Shah",
    start: "2024-04-24T17:00:00.000+00:00",
    end: "2024-04-24T18:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425150,
    user_id: "2443",
    title: "Guilherme Pereira da Silva Alves",
    start: "2024-04-24T17:00:00.000+00:00",
    end: "2024-04-24T18:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425070,
    user_id: "2737",
    title: "Anuradha Katti",
    start: "2024-04-23T20:00:00.000+00:00",
    end: "2024-04-23T21:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425078,
    user_id: "1324",
    title: "Dominique Carmel-Tremblay",
    start: "2024-04-24T09:00:00.000+00:00",
    end: "2024-04-24T10:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425085,
    user_id: "2846",
    title: "Ana Franco",
    start: "2024-04-24T10:00:00.000+00:00",
    end: "2024-04-24T11:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425122,
    user_id: "2659",
    title: "Mike Niu",
    start: "2024-04-24T14:00:00.000+00:00",
    end: "2024-04-24T15:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425123,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-24T14:00:00.000+00:00",
    end: "2024-04-24T15:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425128,
    user_id: "2200",
    title: "Carlos Pinto",
    start: "2024-04-24T15:00:00.000+00:00",
    end: "2024-04-24T16:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425138,
    user_id: "2445",
    title: "Yash Shah",
    start: "2024-04-24T16:00:00.000+00:00",
    end: "2024-04-24T17:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425139,
    user_id: "2443",
    title: "Guilherme Pereira da Silva Alves",
    start: "2024-04-24T16:00:00.000+00:00",
    end: "2024-04-24T17:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425141,
    user_id: "2200",
    title: "Carlos Pinto",
    start: "2024-04-24T16:00:00.000+00:00",
    end: "2024-04-24T17:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425177,
    user_id: "1717",
    title: "Manali Bhattacharyya",
    start: "2024-04-24T19:00:00.000+00:00",
    end: "2024-04-24T20:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425178,
    user_id: "2271",
    title: "Cheng Shi",
    start: "2024-04-24T20:00:00.000+00:00",
    end: "2024-04-24T21:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425244,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-25T15:00:00.000+00:00",
    end: "2024-04-25T16:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425245,
    user_id: "1196",
    title: "Rohit Dhand",
    start: "2024-04-25T15:00:00.000+00:00",
    end: "2024-04-25T16:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425248,
    user_id: "2851",
    title: "Paulo  Eduardo Machado de Campos Filho",
    start: "2024-04-25T15:00:00.000+00:00",
    end: "2024-04-25T16:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425253,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-25T16:00:00.000+00:00",
    end: "2024-04-25T17:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425472,
    user_id: "2765",
    title: "Nicholas Kotsos",
    start: "2024-04-22T15:00:00.000+00:00",
    end: "2024-04-22T16:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425473,
    user_id: "2765",
    title: "Nicholas Kotsos",
    start: "2024-04-22T16:00:00.000+00:00",
    end: "2024-04-22T17:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425476,
    user_id: "2408",
    title: "Pedro Gonzalez",
    start: "2024-04-24T10:00:00.000+00:00",
    end: "2024-04-24T11:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425477,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-24T12:00:00.000+00:00",
    end: "2024-04-24T13:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425478,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-24T13:00:00.000+00:00",
    end: "2024-04-24T14:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425484,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-28T10:00:00.000+00:00",
    end: "2024-04-28T11:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425485,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-28T11:00:00.000+00:00",
    end: "2024-04-28T12:00:00.000+00:00",
    dow: "sunday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425495,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-24T13:00:00.000+00:00",
    end: "2024-04-24T14:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web TI",
  },
  {
    id: 425152,
    user_id: "2200",
    title: "Carlos Pinto",
    start: "2024-04-24T17:00:00.000+00:00",
    end: "2024-04-24T18:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425156,
    user_id: "2271",
    title: "Cheng Shi",
    start: "2024-04-24T18:00:00.000+00:00",
    end: "2024-04-24T19:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425160,
    user_id: "2445",
    title: "Yash Shah",
    start: "2024-04-24T18:00:00.000+00:00",
    end: "2024-04-24T19:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425161,
    user_id: "1855",
    title: "Lovemore Jokonya",
    start: "2024-04-24T18:00:00.000+00:00",
    end: "2024-04-24T19:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425166,
    user_id: "2271",
    title: "Cheng Shi",
    start: "2024-04-24T19:00:00.000+00:00",
    end: "2024-04-24T20:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425174,
    user_id: "1855",
    title: "Lovemore Jokonya",
    start: "2024-04-24T19:00:00.000+00:00",
    end: "2024-04-24T20:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425185,
    user_id: "1855",
    title: "Lovemore Jokonya",
    start: "2024-04-24T20:00:00.000+00:00",
    end: "2024-04-24T21:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425187,
    user_id: "1717",
    title: "Manali Bhattacharyya",
    start: "2024-04-24T20:00:00.000+00:00",
    end: "2024-04-24T21:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425191,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-25T09:00:00.000+00:00",
    end: "2024-04-25T10:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425195,
    user_id: "2473",
    title: "Nosa Agho",
    start: "2024-04-25T09:00:00.000+00:00",
    end: "2024-04-25T10:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425196,
    user_id: "2654",
    title: "Revanth Reddy Sandireddy ",
    start: "2024-04-25T09:00:00.000+00:00",
    end: "2024-04-25T10:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425201,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-25T10:00:00.000+00:00",
    end: "2024-04-25T11:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425205,
    user_id: "2473",
    title: "Nosa Agho",
    start: "2024-04-25T10:00:00.000+00:00",
    end: "2024-04-25T11:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425206,
    user_id: "2654",
    title: "Revanth Reddy Sandireddy ",
    start: "2024-04-25T10:00:00.000+00:00",
    end: "2024-04-25T11:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425211,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-25T11:00:00.000+00:00",
    end: "2024-04-25T12:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425215,
    user_id: "2654",
    title: "Revanth Reddy Sandireddy ",
    start: "2024-04-25T11:00:00.000+00:00",
    end: "2024-04-25T12:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425216,
    user_id: "2647",
    title: "Ahana Ghosh",
    start: "2024-04-25T11:00:00.000+00:00",
    end: "2024-04-25T12:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425218,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-25T12:00:00.000+00:00",
    end: "2024-04-25T13:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425220,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-25T12:00:00.000+00:00",
    end: "2024-04-25T13:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425226,
    user_id: "2647",
    title: "Ahana Ghosh",
    start: "2024-04-25T12:00:00.000+00:00",
    end: "2024-04-25T13:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425227,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-25T13:00:00.000+00:00",
    end: "2024-04-25T14:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425230,
    user_id: "2395",
    title: "David Halpin",
    start: "2024-04-25T13:00:00.000+00:00",
    end: "2024-04-25T14:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425235,
    user_id: "2647",
    title: "Ahana Ghosh",
    start: "2024-04-25T13:00:00.000+00:00",
    end: "2024-04-25T14:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425236,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-25T14:00:00.000+00:00",
    end: "2024-04-25T15:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425237,
    user_id: "2593",
    title: "Warren Uhrich",
    start: "2024-04-25T14:00:00.000+00:00",
    end: "2024-04-25T15:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425254,
    user_id: "1196",
    title: "Rohit Dhand",
    start: "2024-04-25T16:00:00.000+00:00",
    end: "2024-04-25T17:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425255,
    user_id: "2851",
    title: "Paulo  Eduardo Machado de Campos Filho",
    start: "2024-04-25T16:00:00.000+00:00",
    end: "2024-04-25T17:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425261,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-25T17:00:00.000+00:00",
    end: "2024-04-25T18:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425262,
    user_id: "1196",
    title: "Rohit Dhand",
    start: "2024-04-25T17:00:00.000+00:00",
    end: "2024-04-25T18:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425264,
    user_id: "2851",
    title: "Paulo  Eduardo Machado de Campos Filho",
    start: "2024-04-25T17:00:00.000+00:00",
    end: "2024-04-25T18:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425269,
    user_id: "2752",
    title: "Porfirio Mendez Ocampo",
    start: "2024-04-25T17:00:00.000+00:00",
    end: "2024-04-25T18:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425276,
    user_id: "2752",
    title: "Porfirio Mendez Ocampo",
    start: "2024-04-25T18:00:00.000+00:00",
    end: "2024-04-25T19:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425277,
    user_id: "2852",
    title: "Shivangi  Srivastava",
    start: "2024-04-25T18:00:00.000+00:00",
    end: "2024-04-25T19:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 425283,
    user_id: "2606",
    title: "Colin Kierans",
    start: "2024-04-25T19:00:00.000+00:00",
    end: "2024-04-25T20:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425288,
    user_id: "2852",
    title: "Shivangi  Srivastava",
    start: "2024-04-25T19:00:00.000+00:00",
    end: "2024-04-25T20:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 425291,
    user_id: "2606",
    title: "Colin Kierans",
    start: "2024-04-25T20:00:00.000+00:00",
    end: "2024-04-25T21:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425296,
    user_id: "2852",
    title: "Shivangi  Srivastava",
    start: "2024-04-25T20:00:00.000+00:00",
    end: "2024-04-25T21:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 425298,
    user_id: "2849",
    title: "Jaqueline  Nacarate",
    start: "2024-04-26T09:00:00.000+00:00",
    end: "2024-04-26T10:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425308,
    user_id: "2847",
    title: "Antonio Scotland",
    start: "2024-04-26T10:00:00.000+00:00",
    end: "2024-04-26T11:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425314,
    user_id: "2408",
    title: "Pedro Gonzalez",
    start: "2024-04-26T10:00:00.000+00:00",
    end: "2024-04-26T11:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425316,
    user_id: "2847",
    title: "Antonio Scotland",
    start: "2024-04-26T11:00:00.000+00:00",
    end: "2024-04-26T12:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425320,
    user_id: "2408",
    title: "Pedro Gonzalez",
    start: "2024-04-26T11:00:00.000+00:00",
    end: "2024-04-26T12:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425324,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-26T12:00:00.000+00:00",
    end: "2024-04-26T13:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425325,
    user_id: "2847",
    title: "Antonio Scotland",
    start: "2024-04-26T12:00:00.000+00:00",
    end: "2024-04-26T13:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425329,
    user_id: "2468",
    title: "Omobowale Otuyiga",
    start: "2024-04-26T12:00:00.000+00:00",
    end: "2024-04-26T13:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425334,
    user_id: "1759",
    title: "Gary  Jipp",
    start: "2024-04-26T13:00:00.000+00:00",
    end: "2024-04-26T14:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425337,
    user_id: "2468",
    title: "Omobowale Otuyiga",
    start: "2024-04-26T13:00:00.000+00:00",
    end: "2024-04-26T14:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425339,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-26T13:00:00.000+00:00",
    end: "2024-04-26T14:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425347,
    user_id: "2468",
    title: "Omobowale Otuyiga",
    start: "2024-04-26T14:00:00.000+00:00",
    end: "2024-04-26T15:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425348,
    user_id: "1592",
    title: "Francis Bourgouin",
    start: "2024-04-26T14:00:00.000+00:00",
    end: "2024-04-26T15:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425350,
    user_id: "2673",
    title: "Ankur Bag",
    start: "2024-04-26T15:00:00.000+00:00",
    end: "2024-04-26T16:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425353,
    user_id: "2454",
    title: "Lucas Kitteridge",
    start: "2024-04-26T15:00:00.000+00:00",
    end: "2024-04-26T16:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425357,
    user_id: "2414",
    title: "Bradley Mark",
    start: "2024-04-26T15:00:00.000+00:00",
    end: "2024-04-26T16:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 425359,
    user_id: "2673",
    title: "Ankur Bag",
    start: "2024-04-26T16:00:00.000+00:00",
    end: "2024-04-26T17:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 425469,
    user_id: "2691",
    title: "Juliana Ifionu",
    start: "2024-04-27T14:00:00.000+00:00",
    end: "2024-04-27T15:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web TI",
  },
  {
    id: 425490,
    user_id: "1150",
    title: "Kenny Teng",
    start: "2024-04-23T14:00:00.000+00:00",
    end: "2024-04-23T15:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web TI",
  },
  {
    id: 425492,
    user_id: "2647",
    title: "Ahana Ghosh",
    start: "2024-04-24T14:00:00.000+00:00",
    end: "2024-04-24T15:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web TI",
  },
  {
    id: 425493,
    user_id: "2039",
    title: "Sandeep Chopra",
    start: "2024-04-25T15:00:00.000+00:00",
    end: "2024-04-25T16:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web TI",
  },
  {
    id: 425494,
    user_id: "1150",
    title: "Kenny Teng",
    start: "2024-04-23T15:00:00.000+00:00",
    end: "2024-04-23T16:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web TI",
  },
  {
    id: 425304,
    user_id: "2408",
    title: "Pedro Gonzalez",
    start: "2024-04-26T09:00:00.000+00:00",
    end: "2024-04-26T10:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425306,
    user_id: "2849",
    title: "Jaqueline  Nacarate",
    start: "2024-04-26T10:00:00.000+00:00",
    end: "2024-04-26T11:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425371,
    user_id: "2414",
    title: "Bradley Mark",
    start: "2024-04-26T17:00:00.000+00:00",
    end: "2024-04-26T18:00:00.000+00:00",
    dow: "friday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425391,
    user_id: "2394",
    title: "Bryan Gomes",
    start: "2024-04-27T07:00:00.000+00:00",
    end: "2024-04-27T08:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "7",
    tags: "Web",
  },
  {
    id: 425396,
    user_id: "1729",
    title: "Danila Barton-Szabo",
    start: "2024-04-27T07:00:00.000+00:00",
    end: "2024-04-27T08:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "7",
    tags: "Web",
  },
  {
    id: 425397,
    user_id: "2394",
    title: "Bryan Gomes",
    start: "2024-04-27T08:00:00.000+00:00",
    end: "2024-04-27T09:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "8",
    tags: "Web",
  },
  {
    id: 425402,
    user_id: "1729",
    title: "Danila Barton-Szabo",
    start: "2024-04-27T08:00:00.000+00:00",
    end: "2024-04-27T09:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "8",
    tags: "Web",
  },
  {
    id: 425403,
    user_id: "2394",
    title: "Bryan Gomes",
    start: "2024-04-27T09:00:00.000+00:00",
    end: "2024-04-27T10:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425408,
    user_id: "1729",
    title: "Danila Barton-Szabo",
    start: "2024-04-27T09:00:00.000+00:00",
    end: "2024-04-27T10:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 425411,
    user_id: "2510",
    title: "Shaun  Purslow",
    start: "2024-04-27T10:00:00.000+00:00",
    end: "2024-04-27T11:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425414,
    user_id: "2530",
    title: "Brooks Dulla",
    start: "2024-04-27T10:00:00.000+00:00",
    end: "2024-04-27T11:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 425417,
    user_id: "2691",
    title: "Juliana Ifionu",
    start: "2024-04-27T11:00:00.000+00:00",
    end: "2024-04-27T12:00:00.000+00:00",
    dow: "saturday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425470,
    user_id: "2741",
    title: "Matthew Tran",
    start: "2024-04-22T12:00:00.000+00:00",
    end: "2024-04-22T13:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "12",
    tags: "Web",
  },
  {
    id: 425471,
    user_id: "2741",
    title: "Matthew Tran",
    start: "2024-04-22T13:00:00.000+00:00",
    end: "2024-04-22T14:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
  {
    id: 425474,
    user_id: "2765",
    title: "Nicholas Kotsos",
    start: "2024-04-22T17:00:00.000+00:00",
    end: "2024-04-22T18:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "17",
    tags: "Web",
  },
  {
    id: 425475,
    user_id: "2408",
    title: "Pedro Gonzalez",
    start: "2024-04-24T09:00:00.000+00:00",
    end: "2024-04-24T10:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 426483,
    user_id: "1750",
    title: "Alvin Ng",
    start: "2024-04-25T18:00:00.000+00:00",
    end: "2024-04-25T19:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 426516,
    user_id: "2581",
    title: "Adeoye Olatunbosun",
    start: "2024-04-23T14:00:00.000+00:00",
    end: "2024-04-23T15:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 426528,
    user_id: "2741",
    title: "Matthew Tran",
    start: "2024-04-23T19:00:00.000+00:00",
    end: "2024-04-23T20:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 426486,
    user_id: "2607",
    title: "Tausif Khan",
    start: "2024-04-22T18:00:00.000+00:00",
    end: "2024-04-22T19:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "18",
    tags: "Web",
  },
  {
    id: 426531,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-23T10:00:00.000+00:00",
    end: "2024-04-23T11:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "10",
    tags: "Web",
  },
  {
    id: 426487,
    user_id: "2607",
    title: "Tausif Khan",
    start: "2024-04-22T19:00:00.000+00:00",
    end: "2024-04-22T20:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 426532,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-23T11:00:00.000+00:00",
    end: "2024-04-23T12:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "11",
    tags: "Web",
  },
  {
    id: 425699,
    user_id: "2345",
    title: "Sergii Gorbatyi",
    start: "2024-04-24T14:00:00.000+00:00",
    end: "2024-04-24T15:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "14",
    tags: "Web",
  },
  {
    id: 425702,
    user_id: "2345",
    title: "Sergii Gorbatyi",
    start: "2024-04-24T15:00:00.000+00:00",
    end: "2024-04-24T16:00:00.000+00:00",
    dow: "wednesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 426484,
    user_id: "1750",
    title: "Alvin Ng",
    start: "2024-04-25T19:00:00.000+00:00",
    end: "2024-04-25T20:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "19",
    tags: "Web",
  },
  {
    id: 426517,
    user_id: "2581",
    title: "Adeoye Olatunbosun",
    start: "2024-04-23T15:00:00.000+00:00",
    end: "2024-04-23T16:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "15",
    tags: "Web",
  },
  {
    id: 426529,
    user_id: "2741",
    title: "Matthew Tran",
    start: "2024-04-23T20:00:00.000+00:00",
    end: "2024-04-23T21:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 426485,
    user_id: "1750",
    title: "Alvin Ng",
    start: "2024-04-25T20:00:00.000+00:00",
    end: "2024-04-25T21:00:00.000+00:00",
    dow: "thursday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 426518,
    user_id: "2581",
    title: "Adeoye Olatunbosun",
    start: "2024-04-23T16:00:00.000+00:00",
    end: "2024-04-23T17:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "16",
    tags: "Web",
  },
  {
    id: 426530,
    user_id: "2785",
    title: "Alfredo Salazar Vélez",
    start: "2024-04-23T09:00:00.000+00:00",
    end: "2024-04-23T10:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "9",
    tags: "Web",
  },
  {
    id: 426488,
    user_id: "2607",
    title: "Tausif Khan",
    start: "2024-04-22T20:00:00.000+00:00",
    end: "2024-04-22T21:00:00.000+00:00",
    dow: "monday",
    week_of: "2024-04-22_1",
    shift_hour: "20",
    tags: "Web",
  },
  {
    id: 426527,
    user_id: "2345",
    title: "Sergii Gorbatyi",
    start: "2024-04-23T13:00:00.000+00:00",
    end: "2024-04-23T14:00:00.000+00:00",
    dow: "tuesday",
    week_of: "2024-04-22_1",
    shift_hour: "13",
    tags: "Web",
  },
];
