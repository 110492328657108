import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const validTimezones = Intl.supportedValuesOf('timeZone');

function TimezoneToggle({ timezone, setTimezone }) {
  return (
    <DropdownButton className="local-bootstrap mb-2" id="dropdown-basic-button" title={timezone}>
      {validTimezones.map((timezoneObject) => (
        <Dropdown.Item key={timezoneObject} active={timezone === timezoneObject} as="button" onClick={() => setTimezone(timezoneObject)}>{timezoneObject}</Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default TimezoneToggle;

TimezoneToggle.propTypes = {
  timezone: PropTypes.string.isRequired,
  setTimezone: PropTypes.func.isRequired,
};
