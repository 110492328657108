import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function ScheduleTypeToggle({ currentScheduleDisplayed, setCurrentScheduleDisplayed }) {
  return (
    <DropdownButton id="dropdown-basic-button" title={currentScheduleDisplayed}>
      <Dropdown.Item active={currentScheduleDisplayed === 'My Schedule'} as="button" onClick={() => setCurrentScheduleDisplayed('My Schedule')}>My Schedule</Dropdown.Item>
      <Dropdown.Item active={currentScheduleDisplayed === 'Web Schedule'} as="button" onClick={() => setCurrentScheduleDisplayed('Web Schedule')}>Web Schedule</Dropdown.Item>
      <Dropdown.Item active={currentScheduleDisplayed === 'Data Schedule'} as="button" onClick={() => setCurrentScheduleDisplayed('Data Schedule')}>Data Schedule</Dropdown.Item>
      <Dropdown.Item active={currentScheduleDisplayed === 'Cyber Schedule'} as="button" onClick={() => setCurrentScheduleDisplayed('Cyber Schedule')}>Cyber Schedule</Dropdown.Item>
    </DropdownButton>
  );
}

export default ScheduleTypeToggle;

ScheduleTypeToggle.propTypes = {
  currentScheduleDisplayed: PropTypes.string.isRequired,
  setCurrentScheduleDisplayed: PropTypes.func.isRequired,
};
