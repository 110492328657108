import React from "react";
// type EventProps = {
//   id: string;
//   name: string;
//   start: string;
//   end: string;
//   overlapInfo: {
//     maxOverlap: number;
//     overlaps: string[];
//   };
//   isBooked: boolean;
//   isWarning: boolean;
//   day?: number;
//   week?: number;
//   lecturerId?: number;
//   module?: number;
//   type: "WEEK" | "MONTH";
// };
export default function Event(props) {
  const {
    id,
    name,
    start,
    end,
    overlapInfo,
    isWarning,
    isBooked,
    initials,
    instructorName,
    week,
    day,
    module,
    type,
    selectLecture,
    cellOffset,
    conferenceType,
  } = props;

  const gridColumn = new Date(start).getDay();
  const gridRowStart =
    type === "WEEK"
      ? (new Date(start).getHours() - cellOffset) * 2 +
        new Date(start).getMinutes() / 30 +
        1
      : 0;
  const gridRowEnd =
    type === "WEEK"
      ? (new Date(end).getHours() - cellOffset) * 2 +
        new Date(start).getMinutes() / 30 +
        1
      : 0;

  const width = type === "WEEK" ? Math.floor(100 / overlapInfo.maxOverlap) + "%" : "100%";
  const push =
    type === "WEEK"
      ? Math.floor(100 / overlapInfo.maxOverlap) * overlapInfo.overlaps.indexOf(id) + "%"
      : 0;

  const classNames = ["Event", conferenceType];
  !isBooked && classNames.push("empty");
  isBooked && isWarning && classNames.push("warning");

  return (
    <div
      className={classNames.join(" ")}
      style={{
        gridRowStart,
        gridRowEnd,
        gridColumn,
        width,
        left: push,
      }}
      onClick={selectLecture}
      data-text={
        instructorName
          ? `${
              conferenceType !== "officehours" ? conferenceType + ":" : ""
            } ${name} - ${instructorName}`
          : `${conferenceType !== "officehours" ? conferenceType + ":" : ""} ${name}`
      }
    >
      <h1>
        <span>{name.length < 25 ? name : name.substring(0, 25) + "..."}</span>
        <i className={isBooked ? "booked" : "not-booked"}>{initials}</i>
      </h1>
    </div>
  );
}