import React from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

function DeleteForm({ deleteShift, selectedShift }) {
  return (
    <div className="d-flex flex-column align-content-center justify-content-center flex-shrink-1">
      <p>{`Are you sure you want to delete this shift from ${
        selectedShift.start.toString().split(" GMT")[0]
      } to ${
        selectedShift.end.toString().split(" GMT")[0]
      }?`}</p>
      <Button variant="danger" onClick={deleteShift}>
        Delete
      </Button>
    </div>
  );
}

export default DeleteForm;
