import React from "react";
import { useEffect, useMemo, useState } from "react";

export default function InviteModal(props) {
  const {
    program,
    instructors,
    toggleModal,
    conferences,
    sendOne,
    sendAll,
    isProcessing,
    removeOne,
  } = props;

  const [showSent, setShowSent] = useState(false);

  useEffect(() => {
    const escClose = (event) => event.key === "Escape" && toggleModal();

    document.addEventListener("keydown", escClose);

    return () => document.removeEventListener("keydown", escClose);
  });

  const handleCloseClick = (event) => {
    if (event.target === event.currentTarget) {
      toggleModal();
    }
  };

  conferences.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  const parsedInvites = conferences
    .filter((conf) => conf.gcal_event_sent)
    .map((conf) => (
      <li>
        <span>{new Date(conf.start_datetime).toLocaleDateString()}</span>
        <span>{conf.name}</span>
        <span>
          {
            instructors.find(
              (inst) => inst.mentor_clocktower_id === conf.mentor_clocktower_id
            )?.name
          }
        </span>
        <button disabled={isProcessing} onClick={() => removeOne(conf)}>
          Remove Invite
        </button>
      </li>
    ));
  const parsedPending = conferences
    .filter((conf) => !conf.gcal_event_sent)
    .map((conf) => (
      <li>
        <span>{new Date(conf.start_datetime).toLocaleDateString()}</span>
        <span>{conf.name}</span>
        <span>
          {
            instructors.find(
              (inst) => inst.mentor_clocktower_id === conf.mentor_clocktower_id
            )?.name
          }
        </span>
        <button disabled={isProcessing} onClick={() => sendOne(conf)}>
          Send Invite
        </button>
      </li>
    ));

  return (
    <div className="bosun_modal_bg" onClick={handleCloseClick}>
      <div className="bosun_modal invites">
        <h1 className="program">
          <span>{program.name}</span>
          {isProcessing && (
            <span>
              Processing invites <i></i>
            </span>
          )}
        </h1>

        <section>
          <h2>
            <span>Pending calendar invites</span>
            <button
              disabled={isProcessing || parsedPending.length === 0}
              onClick={() =>
                sendAll(conferences.filter((conf) => !conf.gcal_event_sent))
              }
            >
              Send all invites
            </button>
          </h2>
          <ul>{parsedPending}</ul>
        </section>

        <section style={{ marginTop: "4em" }}>
          <h2>
            <span>Sent calendar invites</span>
            <button onClick={() => setShowSent(!showSent)}>
              {showSent ? "Hide sent" : "Show sent"}
            </button>
          </h2>
          {showSent && <ul>{parsedInvites}</ul>}
        </section>
      </div>
    </div>
  );
}
