import { createClient } from "@libsql/client";

const officeHourCohort = {
  id: 123456,
  name: "Office Hours",
  uuid: "3274abb6-d224-49af-868f-9de347339c50",
  startDate: "2023-01-01",
  endDate: "2030-01-01",
  programUuid: "471ac8e4-1df2-47f3-ac3e-ef89a334d48d",
  isExcluded: false,
};

export const wrangleInitialData = (data) => {
  const {
    programs,
    cohorts,
    instructors,
    excludedPrograms,
    initialProgramName,
    programCalendarConfigs,
  } = data;

  const parsedProgams = programs
    .filter(
      (program) =>
        !excludedPrograms.includes(program.uuid) &&
        program.activeAndUpcomingCohortUuids.length > 0
    )
    .map((program) => {
      const validCohortUUIDs = [...program.activeAndUpcomingCohortUuids];
      const activeCohorts = validCohortUUIDs.map((cohortUUID) =>
        cohorts.find((cohort) => cohort.uuid === cohortUUID)
      );

      // if (program.uuid === "471ac8e4-1df2-47f3-ac3e-ef89a334d48d") {
      //   validCohortUUIDs.push("3274abb6-d224-49af-868f-9de347339c50");
      //   activeCohorts.push(officeHourCohort)
      // }


      return {
        ...program,
        activeCohorts,
        activeAndUpcomingCohortUuids:validCohortUUIDs
      };
    });

  const selectedProgramUUID = initialProgramName
    ? programs.find((program) => program.name === initialProgramName).uuid
    : null;

  const parsedProgramCalendarConfigs = programCalendarConfigs.map((conf) => ({
    ...conf,
    daysToShow: JSON.parse(conf.daysToShow),
  }));

  const output = {
    instructors,
    programs: parsedProgams,
    bookedInstructors: [],
    programCalendarConfigs: parsedProgramCalendarConfigs,
    selectedProgramUUID,
  };

  return output;
};

export const fetchPrograms = () => {
  const url = "https://web.compass.lighthouselabs.ca/token_api/programs/";
  const token = "fjk48dlDSJH0djgDJZJwhdhfKOSDHEHGqpqzmxnfASsss00qqq";
  return fetch(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not OK");
      }
      return res.json();
    })
    .then((parsedResponse) => parsedResponse.programs)
    .then((programs) => programs.sort((a, b) => (a.name < b.name ? -1 : 1)));
};

export const fetchConferencesByCohort = (cohortUUID) => {
  // Office hours fix, ugly I know...
  if (cohortUUID === "3274abb6-d224-49af-868f-9de347339c50") {
    const client = createClient({
      url: "libsql://bolsun-temp-francisbourgouin.turso.io",
      authToken:
        "eyJhbGciOiJFZERTQSIsInR5cCI6IkpXVCJ9.eyJhIjoicnciLCJpYXQiOjE3MTI2NzQ1NzgsImlkIjoiYzYyMTg0OTUtMjIyMS00NDIzLWIwMmEtYzFlYWU2YjI5M2ZjIn0.hMTIBcdGb9ptpV_ECQdbrUeCaXA1_S4d4NiZN2sJvURGMMoZc6U_j24F0WLuaevV14vGGNlbzcSLQqg8M74ZAQ",
    });

    return client
      .execute("SELECT * FROM office_hours_entries")
      .then((res) => ({ conferences: res.rows }))
      .catch((err) => console.log(err));
  }

  const url = `https://web.compass.lighthouselabs.ca/token_api/cohorts/${cohortUUID}/conferences`;
  const token = "fjk48dlDSJH0djgDJZJwhdhfKOSDHEHGqpqzmxnfASsss00qqq";
  return fetch(url, { headers: { Authorization: `Bearer ${token}` } }).then((res) => {
    if (!res.ok) {
      return cohortUUID;
      // throw new Error("Network response was not OK");
    }
    return res.json();
  });
  // .then((res) => {
  //   console.log(res);
  //   return res;
  // });
};

export const fetchCohorts = () => {
  const url = "https://web.compass.lighthouselabs.ca/token_api/cohorts/";
  const token = "fjk48dlDSJH0djgDJZJwhdhfKOSDHEHGqpqzmxnfASsss00qqq";
  return fetch(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      if (!res.ok) {
        throw new Error("Network response was not OK");
      }
      return res.json();
    })
    .then((parsedResponse) => parsedResponse.cohorts)
    .then((cohorts) => cohorts.map((cohort) => ({ ...cohort, isExcluded: false })));
};

export const parseFlexModules = (conferences) => {
  const matchingTable = {
    w01: "m01",
    w02: "m01",
    w03: "m01",
    w04: "m01",
    w05: "m02",
    w06: "m03",
    w07: "m03",
    w08: "m04",
    w09: "m04",
    w10: "m04",
    w11: "m05",
    w12: "m05",
    w13: "m05",
    w14: "m06",
    w15: "m06",
    w16: "m07",
    w17: "m07",
    w18: "m07",
    w19: "m07",
    w20: "m08",
    w21: "m08",
    w22: "m09",
    w23: "m09",
    w24: "m09",
    w25: "m09",
    w26: "m09",
    w27: "m10",
  };

  const updatedConferences = [...conferences].map((conference) => ({
    ...conference,
    module_label:
      matchingTable[conference.week_label.split("d")[0]] +
      "d" +
      conference.week_label.split("d")[1],
  }));

  return updatedConferences;
};