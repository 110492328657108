import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

function Claim({ setShowClaimModal, claimShift, selectedShift }) {
  return (
    <div className="d-flex flex-column align-content-center justify-content-center flex-shrink-1">
      <p>{`Are you sure you want to claim this shift from ${selectedShift.start.toString().split(' GMT')[0]} to ${selectedShift.end.toString().split(' GMT')[0]}. This shift requires ${selectedShift.tags.toLowerCase()} skills.`}</p>
      <Button variant="primary" onClick={() => claimShift()}>
        Claim
      </Button>
    </div>
  );
}

export default Claim;

Claim.propTypes = {
  setShowClaimModal: PropTypes.func.isRequired,
  claimShift: PropTypes.func.isRequired,
  selectedShift: PropTypes.shape({
    title: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
    tags: PropTypes.string,
  }).isRequired,
};
